import React, { useContext, useState } from "react";
import { UserContext } from "../App";
import { Link } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Button,
  Box,
  Typography,
  IconButton,
  Menu,
  MenuItem
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import PeopleIcon from "@mui/icons-material/People";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import SecurityIcon from "@mui/icons-material/Security";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import PlaceIcon from "@mui/icons-material/Place";
import EnhancedEncryptionIcon from "@mui/icons-material/EnhancedEncryption";
import logo from "../assets/img/logo.jpg";
import "./index.css";

const Header = () => {
  const { user, setUser } = useContext(UserContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [userMenuEl, setUserMenuEl] = useState(null); // Pentru meniul utilizator

  // Verificare roluri
  const isAdmin = user?.idRole === 1; // Admin
  const isManager = user?.idRole === 2; // Manager
  // const isAgent = user?.idRole === 3; // Agent

  const logout = () => {
    setUser(null);
    sessionStorage.removeItem("user");
    window.location.href = "/"; // Redirecționare la login
  };

  const currentButtonStatus = user ? "Deconectare" : "Autentificare";

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleUserMenuOpen = (event) => {
    setUserMenuEl(event.currentTarget);
  };

  const handleUserMenuClose = () => {
    setUserMenuEl(null);
  };

  return (
    <AppBar position="sticky" sx={{ backgroundColor: "black" }}>
      <Toolbar>
        <Box display="flex" alignItems="center" flexGrow={1}>
          <img
            src={logo}
            alt="Logo aplicație MRC"
            width="50"
            height="40"
            className="pe-2"
          />
          <Typography variant="h6" color="inherit">
            MRC-masina de inlocuire app
          </Typography>
        </Box>

        {/* Meniu desktop */}
        <Box sx={{ display: { xs: "none", sm: "block" } }}>
          <Link to={"/members/dashboard"} className="custom-link">
            <Button color="inherit" startIcon={<HomeIcon />}>
              Prima pagina
            </Button>
          </Link>

          {isAdmin && (
            <>
              <Link to={"/members/addCar"} className="custom-link">
                <Button color="inherit" startIcon={<DirectionsCarIcon />}>
                  Masini
                </Button>
              </Link>
              <Link to={"/members/addUser"} className="custom-link">
                <Button color="inherit" startIcon={<PeopleIcon />}>
                  Utilizatori
                </Button>
              </Link>
              <Link to={"/members/asiguratori"} className="custom-link">
                <Button color="inherit" startIcon={<SecurityIcon />}>
                  Asiguratori
                </Button>
              </Link>
              <Link to={"/members/commissions"} className="custom-link">
                <Button color="inherit" startIcon={<MonetizationOnIcon />}>
                  Comisioane
                </Button>
              </Link>
              <Link to={"/members/locations"} className="custom-link">
                <Button color="inherit" startIcon={<PlaceIcon />}>
                  Locatii
                </Button>
              </Link>
            </>
          )}

          {isManager && (
            <>
              <Link to={"/members/addCar"} className="custom-link">
                <Button color="inherit" startIcon={<DirectionsCarIcon />}>
                  Masini
                </Button>
              </Link>
              <Link to={"/members/addUser"} className="custom-link">
                <Button color="inherit" startIcon={<PeopleIcon />}>
                  Utilizatori
                </Button>
              </Link>
              <Link to={"/members/asiguratori"} className="custom-link">
                <Button color="inherit" startIcon={<SecurityIcon />}>
                  Asiguratori
                </Button>
              </Link>
              <Link to={"/members/commissions"} className="custom-link">
                <Button color="inherit" startIcon={<MonetizationOnIcon />}>
                  Comisioane
                </Button>
              </Link>
              <Link to={"/members/locations"} className="custom-link">
                <Button color="inherit" startIcon={<PlaceIcon />}>
                  Locatii
                </Button>
              </Link>
            </>
          )}

          {/* <Link to={"/members/changePass"} className="custom-link">
            <Button color="inherit" startIcon={<ExitToAppIcon />}>
              Schimba parola
            </Button>
          </Link> */}

          {/* Nume cont și meniu derulant */}
          <Button
            color="inherit"
            onClick={handleUserMenuOpen}
            startIcon={<AccountBoxIcon />}
          >
            {"Contul meu"}
          </Button>
          <Menu
            anchorEl={userMenuEl}
            open={Boolean(userMenuEl)}
            onClose={handleUserMenuClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
          >
            <MenuItem onClick={handleUserMenuClose}>
              <Link to={"/members/changePass"} className="custom-link-mobile">
                Schimba parola
              </Link>
            </MenuItem>
            {/* <MenuItem onClick={logout}>Deconectare</MenuItem> */}
          </Menu>

          {/* ///////////////////////////////////////////////// */}

          <Button
            className="custom-button"
            color="inherit"
            onClick={logout}
            startIcon={<ExitToAppIcon />}
          >
            {currentButtonStatus}
          </Button>
        </Box>

        {/* Meniu mobil */}
        <IconButton
          color="inherit"
          edge="end"
          onClick={handleMenuOpen}
          sx={{ display: { xs: "block", sm: "none" } }}
        >
          <MenuIcon />
        </IconButton>

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={handleMenuClose}>
            <Link to={"/members/dashboard"} className="custom-link-mobile">
              <HomeIcon sx={{ marginRight: 1 }} /> Prima pagina
            </Link>
          </MenuItem>

          {isAdmin && (
            <>
              <MenuItem onClick={handleMenuClose}>
                <Link to={"/members/addCar"} className="custom-link-mobile">
                  <DirectionsCarIcon sx={{ marginRight: 1 }} /> Masini
                </Link>
              </MenuItem>
              <MenuItem onClick={handleMenuClose}>
                <Link to={"/members/addUser"} className="custom-link-mobile">
                  <PeopleIcon sx={{ marginRight: 1 }} /> Utilizatori
                </Link>
              </MenuItem>
              <MenuItem onClick={handleMenuClose}>
                <Link
                  to={"/members/asiguratori"}
                  className="custom-link-mobile"
                >
                  <SecurityIcon sx={{ marginRight: 1 }} /> Asiguratori
                </Link>
              </MenuItem>
              <MenuItem onClick={handleMenuClose}>
                <Link
                  to={"/members/commissions"}
                  className="custom-link-mobile"
                >
                  <MonetizationOnIcon sx={{ marginRight: 1 }} /> Comisioane
                </Link>
              </MenuItem>
              <MenuItem onClick={handleMenuClose}>
                <Link to={"/members/locations"} className="custom-link-mobile">
                  <PlaceIcon sx={{ marginRight: 1 }} /> Locatii
                </Link>
              </MenuItem>
            </>
          )}

          {isManager && (
            <>
              <MenuItem onClick={handleMenuClose}>
                <Link to={"/members/addCar"} className="custom-link-mobile">
                  <DirectionsCarIcon sx={{ marginRight: 1 }} /> Masini
                </Link>
              </MenuItem>
              <MenuItem onClick={handleMenuClose}>
                <Link to={"/members/addUser"} className="custom-link-mobile">
                  <PeopleIcon sx={{ marginRight: 1 }} /> Utilizatori
                </Link>
              </MenuItem>
              <MenuItem onClick={handleMenuClose}>
                <Link
                  to={"/members/asiguratori"}
                  className="custom-link-mobile"
                >
                  <SecurityIcon sx={{ marginRight: 1 }} /> Asiguratori
                </Link>
              </MenuItem>
              <MenuItem onClick={handleMenuClose}>
                <Link
                  to={"/members/commissions"}
                  className="custom-link-mobile"
                >
                  <MonetizationOnIcon sx={{ marginRight: 1 }} /> Comisioane
                </Link>
              </MenuItem>
              <MenuItem onClick={handleMenuClose}>
                <Link to={"/members/locations"} className="custom-link-mobile">
                  <PlaceIcon sx={{ marginRight: 1 }} /> Locatii
                </Link>
              </MenuItem>
            </>
          )}

          <MenuItem onClick={handleMenuClose}>
            <Link to={"/members/changePass"} className="custom-link-mobile">
              <EnhancedEncryptionIcon sx={{ marginRight: 1 }} /> Schimba parola
            </Link>
          </MenuItem>

          <MenuItem onClick={handleMenuClose}>
            <Button
              className="custom-button"
              color="inherit"
              onClick={logout}
              startIcon={<ExitToAppIcon />}
            >
              {currentButtonStatus}
            </Button>
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
