import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as moment from "moment";
import Axios from "axios";

import Header from "../../navigation/header";
import Title from "../../navigation/title";
import { checkIsLoggedIn } from "../../auth/login/Login";
import ContractsList from "./contractsList";
// import CalendarBar from "./calendarBar";

import "./index.css";

export const NewDateFormat = (yourDate) => {
  return moment(yourDate).format("YYYY-MM-DD");
};

const Dashboard = () => {
  const navigate = useNavigate();
  const [contractsList, setContractsList] = useState([]);
  const [carsList, setCarsList] = useState([]);
  const [locationsList, setLocationsList] = useState([]);
  const [managersList, setManagersList] = useState([]);
  const [agentsList, setAgentsList] = useState([]);
  const [counter, setCounter] = useState(0);
  const [imagesList, setImagesList] = useState([]);
  const [agentsListByParentId, setAgentsListByParentId] = useState([]);
  const [invoicesRange, setInvoicesRange] = useState([]);
  const [insurersList, setInsurersList] = useState([]);

  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const userStorage = sessionStorage.getItem("user");
  const userIdStorage = userStorage ? JSON.parse(userStorage).id : null;
  // console.log("userIdStorage", userIdStorage);
  // console.log("contractsList", contractsList);

  const handleChangeData = () => {
    setCounter((prev) => prev + 1);
  };

  const getLocationsList = async () => {
    const response = await Axios.get(`${BASE_URL}/getLocationsList`);
    setLocationsList(response.data);
  };

  const getManagersList = async () => {
    const response = await Axios.get(`${BASE_URL}/getManagersList`);
    setManagersList(response.data);
  };

  const getAgentsList = async () => {
    const response = await Axios.get(`${BASE_URL}/getAgentsList`);
    setAgentsList(response.data);
    // console.log("agentsList", response.data);
  };

  const getAgentsListByParentId = async (id) => {
    const response = await Axios.get(
      `${BASE_URL}/getAgentsListByParentId/${id}`
    );
    setAgentsListByParentId(response.data);
    // return response.data;
  };

  const getCarsList = async () => {
    try {
      const date = new Date();
      const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      const lastDay = new Date(
        date.getFullYear(),
        date.getMonth() + 1,
        0,
        23,
        59,
        59
      );
      const carData = {
        month: date.getMonth() + 1,
        year: date.getFullYear(),
        firstDayOfMonth: NewDateFormat(firstDay),
        lastDayOfMonth: NewDateFormat(lastDay)
      };

      const response = await Axios.post(
        `${BASE_URL}/getCarListForBar/`,
        carData
      );
      setCarsList(response.data);
    } catch (error) {
      console.error("Error fetching car list:", error);
    }
  };

  const getContractsList = async (dataInceput = null, dataSfarsit = null) => {
    const userStorage = sessionStorage.getItem("user");
    let idRole, idUser;

    if (userStorage) {
      const userData = JSON.parse(userStorage);
      idRole = userData.idRole;
      idUser = userData.id;
    } else {
      // console.log("Nu există date stocate în `sessionStorage` pentru userData");
      return; // Iesim din funcție dacă nu avem `userData`
    }

    try {
      // Dacă nu avem datele de început și sfârșit ca argumente, le verificăm în `localStorage`
      if (!dataInceput) {
        dataInceput = localStorage.getItem("dataInceput");
      }
      if (!dataSfarsit) {
        dataSfarsit = localStorage.getItem("dataSfarsit");
      }
      // Dacă nu sunt selectate date, folosim luna curentă ca interval
      const date = new Date();
      const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

      // Adaugă ora 00:00:00 la dataInceput și ora 23:59:59 la dataSfarsit pentru consistență
      const formattedDataInceput = dataInceput
        ? `${dataInceput} 00:00:00`
        : `${NewDateFormat(firstDay)} 00:00:00`;
      const formattedDataSfarsit = dataSfarsit
        ? `${dataSfarsit} 23:59:59`
        : `${NewDateFormat(lastDay)} 23:59:59`;

      const requestData = {
        dataInceput: formattedDataInceput,
        dataSfarsit: formattedDataSfarsit,
        idRole: idRole,
        idUser: idUser
      };

      const response = await Axios.post(
        `${BASE_URL}/contractsList/`,
        requestData
      );
      setContractsList(response.data);
    } catch (error) {
      console.error("Error fetching contracts list:", error);
    }
  };

  const getImagesList = async () => {
    const response = await Axios.get(`${BASE_URL}/getImagesList`);
    setImagesList(response.data);
    // console.log("imagesList", response.data);
  };

  const getInvoicesRange = async () => {
    const response = await Axios.get(`${BASE_URL}/getInvoicesRange`);
    // console.log("InvoicesRange", response.data);
    setInvoicesRange(response.data);
  };

  const getInsurersList = async () => {
    const response = await Axios.get(`${BASE_URL}/insurersList`);
    // console.log("insurersList", response.data);
    setInsurersList(response.data);
  };

  useEffect(() => {
    getLocationsList();
    getManagersList();
    getAgentsList();
    getCarsList();
    getContractsList();
    getImagesList();
    getAgentsListByParentId(userIdStorage);
    getInvoicesRange();
    getInsurersList();
  }, [counter]);

  // console.log("contractsListSTATE", contractsList);

  useEffect(() => {
    if (!checkIsLoggedIn()) {
      navigate("../../auth/login/Login");
    }
  }, [navigate]);
  // console.log("contractsListDASH", contractsList);
  return (
    <div className="dashboard">
      <Header className="header" />
      <Title title="Lista contracte" style={{ marginBottom: "20px" }} />
      <ContractsList
        className="contracts-list"
        contracts={contractsList}
        locationsList={locationsList}
        managersList={managersList}
        agentsList={agentsList}
        carsList={carsList}
        imagesList={imagesList}
        agentsListByParentId={agentsListByParentId}
        invoicesRange={invoicesRange}
        insurersList={insurersList}
        handleChangeData={handleChangeData}
        getContractsList={getContractsList}
      />
      {/* <div className=" table-responsive">
        <div className="calendarBarFooter">
          {carsList.map((car) => (
            <CalendarBar key={car.carId} carId={car.carId} date={new Date()} />
          ))}
          <div style={{ height: "50px", background: "red" }}>Dummy Footer</div>
        </div>
      </div> */}
    </div>
  );
};

export default Dashboard;
