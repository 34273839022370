import React, { useState, useEffect } from "react";
import Axios from "axios";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { PieChart } from "@mui/x-charts/PieChart";
import { Container, Row, Col } from "react-bootstrap";

import "./index.css";

const RentedCarsByUserPerYear = () => {
  const [rentedCarsPerYear, setRentedCarsPerYear] = useState([]);
  const [error, setError] = useState(false);
  const d = new Date();
  const year = d.getFullYear();
  const [selectedYear2, setSelectedYear2] = useState(year);
  const [totalsPerUserPerYear, setTotalsPerUserPerYear] = useState([]);
  const [years, setYears] = useState([]);
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    const fetchYears = async () => {
      try {
        const response = await Axios.get(`${BASE_URL}/years/`);
        setYears(response.data);
      } catch (err) {
        setError(true);
      }
    };
    fetchYears();
  }, []);

  const handleYearChange2 = (event) => {
    setSelectedYear2(event.target.value);
  };

  const rowColored = (item) => {
    return item % 2 === 0 ? "border border-1 table-light" : "border border-1";
  };

  useEffect(() => {
    const fetchData = async () => {
      const userData = { year: selectedYear2 };
      try {
        const response = await Axios.post(
          `${BASE_URL}/rentedCarsPerYear`,
          userData
        );
        setRentedCarsPerYear(response.data);
      } catch (err) {
        setError(true);
      }
    };
    fetchData();
  }, [selectedYear2]);

  useEffect(() => {
    const carsWithUniqueIdPerYear = [];
    const carsWithDetailsPerYear = [];

    rentedCarsPerYear.forEach((user) => {
      if (
        !carsWithUniqueIdPerYear.find((car) => car.idAgent === user.idAgent)
      ) {
        carsWithUniqueIdPerYear.push(user);
      }
    });

    carsWithUniqueIdPerYear.forEach((user) => {
      const incasatPerYear = rentedCarsPerYear
        .filter((car) => car.idAgent === user.idAgent)
        .reduce((sum, car) => sum + parseInt(car.tarif || 0), 0);

      const nrInchirieriPerYear = rentedCarsPerYear.filter(
        (car) => car.idAgent === user.idAgent
      ).length;

      carsWithDetailsPerYear.push({
        idUser: user.idAgent,
        nume: user.nume,
        prenume: user.prenume,
        incasat: incasatPerYear,
        nrInchirieri: nrInchirieriPerYear
      });
    });

    setTotalsPerUserPerYear(carsWithDetailsPerYear);
  }, [rentedCarsPerYear]);

  return (
    <div>
      {error && (
        <p style={{ color: "red" }}>
          Eroare la încărcarea datelor. Vă rugăm să încercați din nou.
        </p>
      )}
      <div
        className="filters"
        style={{
          paddingTop: "30px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <Card sx={{ width: 600, maxWidth: 735 }}>
          <CardContent>
            <FormControl className="formControl">
              <InputLabel id="demo-simple-select-labely">Anul</InputLabel>
              <Select
                labelId="demo-simple-select-labelyear"
                id="year"
                value={selectedYear2}
                onChange={handleYearChange2}
              >
                {years.map((year) => (
                  <MenuItem key={year} value={year.year}>
                    {year.year}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Container fluid>
              <Row>
                <Col>
                  <h2 className="text-center">Raport anual inchirieri/agent</h2>
                </Col>
              </Row>
              <Row>
                <Col>
                  <table className="table table-hover">
                    <thead>
                      <tr className="border border-1 table-dark">
                        <th className="text-center" style={{ width: "80px" }}>
                          Nr. Crt.
                        </th>
                        <th className="text-center">Agent</th>
                        <th className="text-center">Inchirieri</th>
                        {/* <th className="text-center">Incasat</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {totalsPerUserPerYear.map((item, index) => (
                        <tr key={index} className={rowColored(index)}>
                          <td className="text-center">{index + 1}</td>
                          <td className="text-center">
                            {item.nume} {item.prenume}
                          </td>
                          <td className="text-center">{item.nrInchirieri}</td>
                          {/* <td className="text-center">{item.incasat}</td> */}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Col>
              </Row>
            </Container>
          </CardContent>
          <CardContent>
            <PieChart
              series={[
                {
                  data: totalsPerUserPerYear.map((item, index) => ({
                    id: index,
                    value: item.incasat,
                    label: `${item.nume} ${item.prenume}`
                  }))
                }
              ]}
              width={400}
              height={300}
            />
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default RentedCarsByUserPerYear;
