import React, { useState, useEffect } from "react";
import {
  Grid,
  TextField,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const AddContractForm = (props) => {
  const [formData, setFormData] = useState({
    locatie: "",
    manager: "",
    agent: "",
    conducatorAuto: "",
    conducatorAutoCi: "",
    conducatorAutoTelefon: "",
    numePagubit: "",
    cnp: "",
    telefon: "",
    adresa: "",
    dataEveniment: ""
  });

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [open, setOpen] = useState(false);
  const [filteredAgents, setFilteredAgents] = useState([]);
  const [filteredManagers, setFilteredManagers] = useState([]);

  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const handleClose = () => {
    console.log("Closed");
    setOpen(false);
  };

  const validateCNP = (cnp) => {
    if (!/^[0-9]{13}$/.test(cnp)) {
      return false; // Verifică dacă are exact 13 cifre
    }

    const controlWeights = [2, 7, 9, 1, 4, 6, 3, 5, 8, 2, 7, 9];
    const sum = cnp
      .split("")
      .slice(0, 12)
      .reduce((acc, digit, index) => acc + digit * controlWeights[index], 0);
    const controlDigit = sum % 11 === 10 ? 1 : sum % 11;
    return controlDigit === parseInt(cnp[12], 10);
  };

  const validatePhone = (phone) => {
    const romanianPhoneRegex = /^(?:\+40|0)(7[2-8]\d{7}|2\d{8})$/; // Prefixe pentru mobil și fix
    return romanianPhoneRegex.test(phone);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === "manager") {
      // Filtrare agenți în funcție de managerul selectat (folosind idParinte)
      const filtered = props.agentsList.filter(
        (agent) => parseInt(agent.idParinte) === parseInt(value)
      );

      const manager = props.managersList.find(
        (manager) => parseInt(manager.id) === parseInt(value)
      );

      if (manager) {
        filtered.push({
          id: manager.id,
          nume: manager.nume,
          prenume: manager.prenume,
          rol: "Manager"
        });
      }

      setFilteredAgents(filtered);
      setFormData((prevFormData) => ({
        ...prevFormData,
        manager: value,
        agent: ""
      }));
    }
  };

  const handleCancel = () => {
    setFormData({
      locatie: "",
      manager: props.userRole === 2 ? props.userId : "",
      agent: "",
      conducatorAuto: "",
      conducatorAutoCi: "",
      conducatorAutoTelefon: "",
      numePagubit: "",
      cnp: "",
      telefon: "",
      adresa: "",
      dataEveniment: ""
    });
    setErrors({});
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.locatie) newErrors.locatie = "Locația este obligatorie";
    if (!formData.manager) newErrors.manager = "Managerul este obligatoriu";
    if (!formData.agent) newErrors.agent = "Agentul este obligatoriu";
    if (!formData.numePagubit)
      newErrors.numePagubit = "Numele pagubitului este obligatoriu";
    if (!formData.cnp) newErrors.cnp = "CNP-ul este obligatoriu";
    else if (!validateCNP(formData.cnp)) newErrors.cnp = "CNP-ul nu este valid";

    if (!formData.telefon)
      newErrors.telefon = "Numărul de telefon este obligatoriu";
    else if (!validatePhone(formData.telefon))
      newErrors.telefon = "Numărul de telefon nu este valid";

    if (!formData.adresa) newErrors.adresa = "Adresa este obligatorie";
    if (!formData.dataEveniment)
      newErrors.dataEveniment = "Data si ora evenimentului sunt obligatorii";

    setErrors(newErrors);

    // Returnează true dacă nu există erori
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setIsSubmitting(true);

      try {
        const response = await fetch(`${BASE_URL}/addNewContract`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify(formData)
        });

        if (!response.ok) {
          throw new Error("Eroare la trimiterea contractului");
        }

        setFormData({
          locatie: "",
          manager: props.userRole === 2 ? props.userId : "",
          agent: "",
          conducatorAuto: "",
          conducatorAutoCi: "",
          conducatorAutoTelefon: "",
          numePagubit: "",
          cnp: "",
          telefon: "",
          adresa: "",
          dataEveniment: ""
        });
        setErrors({});
        setOpen(true);
      } catch (error) {
        alert("Eroare: " + error.message);
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (props.managersList && props.agentsList) {
      if (props.userRole === 1) {
        // Dacă utilizatorul este admin, arătăm managerii care au idParinte egal cu ID-ul utilizatorului logat
        const managers = props.managersList.filter(
          (manager) => parseInt(manager.idParinte) === parseInt(props.userId)
        );

        setFilteredManagers(managers);
      } else if (props.userRole === 2) {
        // Dacă utilizatorul este manager, arătăm doar managerul logat
        const manager = props.managersList.find(
          (manager) => parseInt(manager.id) === parseInt(props.userId)
        );
        // console.log("Manager pentru manager logat:", manager);
        setFilteredManagers(manager ? [manager] : []);

        // Setăm și agenții managerului logat (folosind idParinte)
        const agents = props.agentsList.filter(
          (agent) => parseInt(agent.idParinte) === parseInt(props.userId)
        );

        if (manager) {
          agents.push({
            id: manager.id,
            nume: manager.nume,
            prenume: manager.prenume,
            rol: "Manager"
          });
        }

        setFilteredAgents(agents);
      }
    }
  }, [props.userRole, props.userId, props.managersList, props.agentsList]);

  return (
    <div>
      <h2>Adaugă Dosar Nou</h2>
      <Button
        variant="text"
        color="error"
        onClick={() => {
          handleClose();
          props.onClose();
        }}
        size="large"
        style={{ position: "absolute", right: 0, top: 0 }}
      >
        <CloseIcon fontSize="medium" />
      </Button>
      <form
        onSubmit={handleSubmit}
        style={{ maxWidth: "1000px", margin: "0 auto" }}
      >
        <Grid container spacing={2}>
          {/* Prima linie: Locatie, Manager, Agent */}
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth error={!!errors.locatie}>
              <InputLabel>Locație</InputLabel>
              <Select
                name="locatie"
                value={formData.locatie}
                onChange={handleChange}
                fullWidth
              >
                {props.locationsList?.map((location) => (
                  <MenuItem key={location.idLocatie} value={location.idLocatie}>
                    {location?.localitatea} - {location.adresa}
                  </MenuItem>
                ))}
              </Select>
              {errors.locatie && (
                <p style={{ color: "red" }}>{errors.locatie}</p>
              )}
            </FormControl>
          </Grid>

          {/* Selectare Manager */}
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth error={!!errors.manager}>
              <InputLabel>Manager</InputLabel>
              <Select
                name="manager"
                value={formData.manager}
                onChange={handleChange}
                fullWidth
              >
                {filteredManagers.map((manager) => (
                  <MenuItem key={manager.id} value={manager.id}>
                    {manager.nume} {manager.prenume}
                  </MenuItem>
                ))}
              </Select>
              {errors.manager && (
                <p style={{ color: "red" }}>{errors.manager}</p>
              )}
            </FormControl>
          </Grid>

          {/* Selectare Agent */}
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth error={!!errors.agent}>
              <InputLabel>Agent</InputLabel>
              <Select
                name="agent"
                value={formData.agent}
                onChange={handleChange}
                fullWidth
              >
                {filteredAgents.length > 0 ? (
                  filteredAgents.map((agent) => (
                    <MenuItem key={agent.id} value={agent.id}>
                      {agent.nume} {agent.prenume}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>Alege mai întâi un manager</MenuItem>
                )}
              </Select>
              {errors.agent && <p style={{ color: "red" }}>{errors.agent}</p>}
            </FormControl>
          </Grid>

          {/* A doua linie: conducator auto, CNP, Telefon */}
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              name="conducatorAuto"
              label="Nume si prenume conducator auto"
              onChange={handleChange}
              value={formData.conducatorAuto}
              error={!!errors.conducatorAuto}
              helperText={errors.conducatorAuto}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              name="conducatorAutoCi"
              label="CI conducator auto"
              onChange={handleChange}
              value={formData.conducatorAutoCi}
              error={!!errors.conducatorAutoCi}
              helperText={errors.conducatorAutoCi}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              name="conducatorAutoTelefon"
              label="Telefon conducator auto"
              onChange={handleChange}
              value={formData.conducatorAutoTelefon}
              error={!!errors.conducatorAutoTelefon}
              helperText={errors.conducatorAutoTelefon}
            />
          </Grid>

          {/* A treia coloana: Nume Pagubit, CNP, Telefon, Adresa */}
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              name="numePagubit"
              label="Nume Pagubit"
              onChange={handleChange}
              value={formData.numePagubit}
              error={!!errors.numePagubit}
              helperText={errors.numePagubit}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              name="cnp"
              label="CNP"
              onChange={handleChange}
              value={formData.cnp}
              error={!!errors.cnp}
              helperText={errors.cnp}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              name="telefon"
              label="Telefon"
              onChange={handleChange}
              value={formData.telefon}
              error={!!errors.telefon}
              helperText={errors.telefon}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              name="dataEveniment"
              label="Data și Ora Evenimentului"
              type="datetime-local"
              InputLabelProps={{ shrink: true }}
              onChange={handleChange}
              value={formData.dataEveniment}
              error={!!errors.dataEveniment}
              helperText={errors.dataEveniment}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              name="adresa"
              label="Adresă"
              onChange={handleChange}
              value={formData.adresa}
              error={!!errors.adresa}
              helperText={errors.adresa}
            />
          </Grid>

          {/* Butoane */}
          <Grid item xs={12} sm={6}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              disabled={isSubmitting}
            >
              Adaugă Contract
            </Button>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Button
              fullWidth
              variant="outlined"
              onClick={handleCancel}
              disabled={isSubmitting}
            >
              Resetează Formularul
            </Button>
          </Grid>
        </Grid>

        {/* Dialog pentru confirmarea trimiterii */}
        <Dialog open={open} onClose={handleDialogClose}>
          <DialogTitle>Contract adăugat cu succes!</DialogTitle>
          <DialogContent>
            Contractul a fost adăugat cu succes în sistem.
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} color="primary">
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </form>
    </div>
  );
};

export default AddContractForm;
