import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Modal,
  TextField,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CancelIcon from "@mui/icons-material/Cancel";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";

const DateTimeModal = ({ contract, open, onClose, handleChangeDatas }) => {
  const [dataInceput, setDataInceput] = useState("");
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const BASE_URL = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    if (contract) {
      // Setează data de început fie din contract, fie data și ora curentă
      const initialData = contract?.dataInceput
        ? formatDateTimeForInput(new Date(contract.dataInceput))
        : formatDateTimeForInput(new Date());
      setDataInceput(initialData);

      setErrorMessage("");

      const nrOre = contract.nrOre;
      if (!nrOre) {
        setErrorMessage("Te rugăm să alegi mai întâi numarul de ore.");
        return;
      }
    } else {
      setErrorMessage("Contractul nu este definit.");
    }
  }, [contract]);

  const formatDateTimeForInput = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  const handleSave = async () => {
    // console.log("dataInceput", dataInceput); // Folosește starea locală

    // Transformăm string-ul din starea locală în obiect Date
    const startDate = new Date(dataInceput);

    // Calculează endDate
    const endDate = new Date(startDate);
    endDate.setDate(startDate.getDate() + parseInt(contract.nrZile));

    // Formatează datele pentru backend
    const formattedStartDate = formatDateTimeForInput(startDate);
    const formattedEndDate = formatDateTimeForInput(endDate);
    // console.log("formattedStartDate", formattedStartDate);
    // console.log("formattedEndDate", formattedEndDate);

    try {
      const response = await fetch(`${BASE_URL}/updateDateTime`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          ContractId: contract.idContract,
          dataInceput: formattedStartDate,
          dataSfarsit: formattedEndDate
        })
      });

      if (response.ok) {
        handleChangeDatas();
        onClose();
      } else {
        console.error("Eroare la salvarea datei de început");
      }
    } catch (error) {
      console.error("Eroare de rețea:", error);
    }
  };

  const handleDeleteConfirm = () => {
    setConfirmDeleteOpen(true);
  };

  const handleDelete = async () => {
    try {
      const response = await fetch(`${BASE_URL}/deleteDateTime`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ ContractId: contract.idContract })
      });

      if (response.ok) {
        handleChangeDatas();
        onClose();
      } else {
        console.error("Eroare la ștergerea datei de început");
      }
      setConfirmDeleteOpen(false);
    } catch (error) {
      console.error("Eroare de rețea:", error);
    }
  };

  console.log("errorMessage", errorMessage);
  return (
    <>
      <Modal open={open} onClose={onClose}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "400px",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: "10px"
          }}
        >
          <IconButton
            onClick={onClose}
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
              color: "red"
            }}
          >
            <CloseIcon />
          </IconButton>

          {errorMessage && (
            <Typography color="error" sx={{ mb: 2 }}>
              {errorMessage}
            </Typography>
          )}
          {!errorMessage && (
            <h4 style={{ paddingTop: "30px", paddingBottom: "20px" }}>
              Adaugă/Editează data de început
            </h4>
          )}
          <TextField
            label="Data de Început"
            type="datetime-local"
            fullWidth
            value={dataInceput}
            onChange={(e) => setDataInceput(e.target.value)}
            sx={{ marginBottom: 2 }}
            InputLabelProps={{
              shrink: true
            }}
            disabled={!!errorMessage}
          />

          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              variant="outlined"
              onClick={handleSave}
              startIcon={<SaveIcon />}
              disabled={!!errorMessage}
            >
              Salvează
            </Button>
            <Button
              variant="outlined"
              color="error"
              onClick={handleDeleteConfirm}
              startIcon={<DeleteIcon />}
              disabled={!!errorMessage}
            >
              Șterge
            </Button>
          </Box>
        </Box>
      </Modal>

      <Dialog
        open={confirmDeleteOpen}
        onClose={() => setConfirmDeleteOpen(false)}
        sx={{ textAlign: "center", padding: "20px" }}
      >
        <DialogTitle>Confirmare ștergere</DialogTitle>
        <DialogContent>
          <p>Ești sigur că vrei să ștergi?</p>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setConfirmDeleteOpen(false)}
            color="primary"
            variant="outlined"
            startIcon={<CancelIcon />}
          >
            Anulează
          </Button>
          <Button
            onClick={handleDelete}
            color="error"
            variant="outlined"
            startIcon={<DeleteIcon />}
          >
            Șterge
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DateTimeModal;
