import React, { useState, useEffect } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TextField,
  Button,
  Card,
  CardMedia,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  MenuItem,
  Select,
  InputLabel,
  FormControl
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import CloseIcon from "@mui/icons-material/Close";
// import TimerOffIcon from "@mui/icons-material/TimerOff";
// import formatDate from "../../utils/dateFormat";
import { formatLocalDateTime } from "../../utils/dateFormat";
import "./index.css";

const ContractDetails = ({
  contracts,
  idContract,
  onSave,
  imagesList,
  insurersList
}) => {
  const [formData, setFormData] = useState(() => {
    const initialPozeEveniment = imagesList[idContract]
      ? imagesList[idContract].filter((img) =>
          img.denumire.toLowerCase().includes("pozaeveniment")
        )
      : [];
    return {
      dataConstatare: "",
      numarDosar: "",
      asigurator: "",
      inspector: "",
      conducatorAuto: "",
      conducatorAutoCi: "",
      conducatorAutoTelefon: "",
      pozeActe: {
        ci_vatamat: "",
        permis_vatamat: "",
        rca_vatamat: "",
        certificatAuto_vatamat: "",
        amiabila_vatamat: "",
        ci_vinovat: "",
        permis_vinovat: "",
        rca_vinovat: "",
        certificatAuto_vinovat: ""
      },
      pozeMasina: {
        dreapta_fata: "",
        dreapta_spate: "",
        stanga_fata: "",
        stanga_spate: "",
        bord: "",
        serie_sasiu: ""
      },
      // pozeEveniment: Array(6).fill(null)
      pozeEveniment:
        initialPozeEveniment.length > 0
          ? initialPozeEveniment
          : Array(6).fill(null)
    };
  });

  const [selectedImage, setSelectedImage] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  const handleInputChange = (e, field) => {
    const { value } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [field]: value
    }));
  };

  const handlePozeChange = (e, key, contractId) => {
    const file = e.target.files[0];
    if (file) {
      const labelToNameMapping = {
        ci_vatamat: "ci_vatamat",
        permis_vatamat: "permis_vatamat",
        rca_vatamat: "rca_vatamat",
        certificatAuto_vatamat: "certificatAuto_vatamat",
        amiabila_vatamat: "amiabila_vatamat",
        ci_vinovat: "ci_vinovat",
        permis_vinovat: "permis_vinovat",
        rca_vinovat: "rca_vinovat",
        certificatAuto_vinovat: "certificatAuto_vinovat"
      };

      const labelName = labelToNameMapping[key] || key;
      const fileExtension = file.name.split(".").pop(); // Extract file extension
      const newFileName = `${contractId}-${labelName}.${fileExtension}`;
      const updatedFile = new File([file], newFileName, {
        type: file.type
      });

      setFormData((prevData) => ({
        ...prevData,
        pozeActe: {
          ...prevData.pozeActe,
          [key]: {
            file: updatedFile,
            previewUrl: URL.createObjectURL(updatedFile)
          }
        }
      }));
      console.log("Updated file:", updatedFile);
    }
  };

  const handleMasinaEvenimentChange = (e, type, key, contractId) => {
    if (!e && type && key) {
      // Șterge imaginea
      if (type === "pozeMasina") {
        setFormData((prevData) => ({
          ...prevData,
          pozeMasina: {
            ...prevData.pozeMasina,
            [key]: {
              file: null,
              previewUrl: null
            }
          }
        }));
      } else if (type === "pozeEveniment") {
        setFormData((prevData) => {
          const updatedPozeEveniment = [...prevData.pozeEveniment];
          updatedPozeEveniment[key] = null;
          return { ...prevData, pozeEveniment: updatedPozeEveniment };
        });
      }
      return; // Oprirea execuției funcției
    }

    if (e && e.target) {
      const file = e.target.files[0];
      if (file) {
        const labelToNameMapping = {
          masina: key,
          eveniment: `poza_eveniment_${key + 1}`
        };

        const labelName = labelToNameMapping[type] || type;
        const fileExtension = file.name.split(".").pop(); // Extract file extension
        const newFileName = `${contractId}-${labelName}.${fileExtension}`;
        const updatedFile = new File([file], newFileName, {
          type: file.type
        });

        if (type === "pozeMasina") {
          setFormData((prevData) => ({
            ...prevData,
            pozeMasina: {
              ...prevData.pozeMasina,
              [key]: {
                file: updatedFile,
                previewUrl: URL.createObjectURL(updatedFile) // Creează un URL pentru previzualizare
              }
            }
          }));
        } else if (type === "pozeEveniment") {
          setFormData((prevData) => {
            const updatedPozeEveniment = [...prevData.pozeEveniment];
            updatedPozeEveniment[key] = {
              file: updatedFile,
              previewUrl: URL.createObjectURL(updatedFile) // Creează un URL pentru previzualizare
            };
            return { ...prevData, pozeEveniment: updatedPozeEveniment };
          });
        }
      }
    }
  };

  const handleImageClick = (image) => {
    if (typeof image === "string") {
      setSelectedImage(image);
    } else if (image instanceof File) {
      setSelectedImage(URL.createObjectURL(image));
    }
    setOpenDialog(true);
  };

  const handleImageDelete = async (key, type) => {
    const confirmDelete = window.confirm(
      "Ești sigur că vrei să ștergi această imagine?"
    );
    if (!confirmDelete) {
      return; // Dacă utilizatorul anulează, nu continuăm ștergerea
    }

    console.log(`Attempting to delete image of type: ${type}, key: ${key}`);

    // Verifică dacă imaginea este încărcată local (obiect File) sau vine din baza de date
    if (type === "pozeActe") {
      if (formData[type][key] && formData[type][key].file) {
        // Imagine încărcată recent, elimin-o din formData
        setFormData((prevData) => ({
          ...prevData,
          [type]: {
            ...prevData[type],
            [key]: null // Resetează câmpul respectiv
          }
        }));
        console.log(`Image from local upload removed for ${type}, key: ${key}`);
      } else {
        // Imagine existentă în baza de date, trimite cerere către API
        try {
          const response = await fetch(
            `${process.env.REACT_APP_BASE_URL}/deleteImage/${idContract}/${key}`,
            {
              method: "DELETE"
            }
          );

          if (response.ok) {
            // Elimină imaginea din formData după ștergerea cu succes din baza de date
            setFormData((prevData) => ({
              ...prevData,
              [type]: {
                ...prevData[type],
                [key]: null // Resetează câmpul respectiv
              }
            }));

            // Actualizează `imagesList` pentru a elimina imaginea ștearsă
            setFormData((prevData) => {
              const updatedImagesList = imagesList[idContract].filter(
                (img) =>
                  !img.denumire
                    .toLowerCase()
                    .startsWith(`${idContract}-pozeMasina_${key}`.toLowerCase())
              );
              return {
                ...prevData,
                imagesList: {
                  ...prevData.imagesList,
                  [idContract]: updatedImagesList
                }
              };
            });

            console.log(
              `Image successfully deleted from server for ${type}, key: ${key}`
            );
          } else {
            console.error(
              `Failed to delete image from server for ${type}, key: ${key}`
            );
          }
        } catch (error) {
          console.error(
            `Error deleting image from server for ${type}, key: ${key}`,
            error
          );
        }
      }
    } else if (type === "pozeMasina") {
      if (formData.pozeMasina[key] && formData.pozeMasina[key].file) {
        // Imagine încărcată recent, elimin-o din formData
        setFormData((prevData) => ({
          ...prevData,
          pozeMasina: {
            ...prevData.pozeMasina,
            [key]: null
          }
        }));
        console.log(
          `Image from local upload removed for pozeMasina, key: ${key}`
        );
      } else {
        // Imagine existentă în baza de date, trimite cerere către API
        try {
          const response = await fetch(
            `${process.env.REACT_APP_BASE_URL}/deleteImage/${idContract}/pozeMasina_${key}`,
            {
              method: "DELETE"
            }
          );

          if (response.ok) {
            // Elimină imaginea din formData după ștergerea cu succes din baza de date
            setFormData((prevData) => ({
              ...prevData,
              pozeMasina: {
                ...prevData.pozeMasina,
                [key]: null
              }
            }));

            // Actualizează `imagesList` pentru a elimina imaginea ștearsă
            setFormData((prevData) => {
              const updatedImagesList = imagesList[idContract].filter(
                (img) =>
                  !img.denumire
                    .toLowerCase()
                    .startsWith(`${idContract}-pozaMasina_${key}`.toLowerCase())
              );
              return {
                ...prevData,
                imagesList: {
                  ...prevData.imagesList,
                  [idContract]: updatedImagesList
                }
              };
            });

            console.log(
              `Image successfully deleted from server for pozeMasina, key: ${key}`
            );
          } else {
            console.error(
              `Failed to delete image from server for pozeMasina, key: ${key}`
            );
          }
        } catch (error) {
          console.error(
            `Error deleting image from server for pozeMasina, key: ${key}`,
            error
          );
        }
      }
    } else if (type === "pozeEveniment") {
      if (formData.pozeEveniment[key] && formData.pozeEveniment[key].file) {
        // Imagine încărcată recent, elimin-o din formData
        setFormData((prevData) => {
          const updatedPozeEveniment = [...prevData.pozeEveniment];
          updatedPozeEveniment[key] = null;
          return { ...prevData, pozeEveniment: updatedPozeEveniment };
        });
        console.log(
          `Image from local upload removed for pozeEveniment, key: ${key}`
        );
      } else {
        // Imagine existentă în baza de date, trimite cerere către API
        try {
          const response = await fetch(
            `${process.env.REACT_APP_BASE_URL}/deleteImage/${idContract}/poza_eveniment_${key}`,
            {
              method: "DELETE"
            }
          );

          if (response.ok) {
            // Elimină imaginea din formData după ștergerea cu succes din baza de date
            setFormData((prevData) => {
              const updatedPozeEveniment = [...prevData.pozeEveniment];
              updatedPozeEveniment[key] = null;
              return { ...prevData, pozeEveniment: updatedPozeEveniment };
            });

            // Actualizează `imagesList` pentru a elimina imaginea ștearsă
            setFormData((prevData) => {
              const updatedImagesList = imagesList[idContract].filter(
                (img) =>
                  !img.denumire
                    .toLowerCase()
                    .startsWith(
                      `${idContract}-pozaEveniment_${key}`.toLowerCase()
                    )
              );
              return {
                ...prevData,
                imagesList: {
                  ...prevData.imagesList,
                  [idContract]: updatedImagesList
                }
              };
            });

            console.log(
              `Image successfully deleted from server for pozeEveniment, key: ${key}`
            );
          } else {
            console.error(
              `Failed to delete image from server for pozeEveniment, key: ${key}`
            );
          }
        } catch (error) {
          console.error(
            `Error deleting image from server for pozeEveniment, key: ${key}`,
            error
          );
        }
      }
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedImage(null);
  };

  const handleAddPhoto = () => {
    setFormData((prevState) => {
      return {
        ...prevState,
        pozeEveniment: [
          ...prevState.pozeEveniment,
          {
            file: null,
            previewUrl: null
          } // Adaugă un nou input pentru imagine
        ]
      };
    });
  };

  // const handleReset = () => {
  //   setFormData({
  //     dataConstatare: "",
  //     numarDosar: "",
  //     asigurator: "",
  //     inspector: "",
  //     pozeActe: {
  //       ci_vatamat: "",
  //       permis_vatamat: "",
  //       rca_vatamat: "",
  //       certificatAuto_vatamat: "",
  //       amiabila_vatamat: "",
  //       ci_vinovat: "",
  //       permis_vinovat: "",
  //       rca_vinovat: "",
  //       certificatAuto_vinovat: ""
  //     },
  //     pozeMasina: [
  //       { file: "", label: "Dreapta față" },
  //       { file: "", label: "Dreapta spate" },
  //       { file: "", label: "Stânga față" },
  //       { file: "", label: "Stânga spate" },
  //       { file: "", label: "Bord" },
  //       { file: "", label: "Serie sasiu" }
  //     ],
  //     pozeEveniment: Array(6).fill(null)
  //   });
  // };

  const handleSave = () => {
    const updatedFormData = {
      ...formData,
      idContract // Include idContract de la props
    };
    // console.log("Datele au fost salvate:", updatedFormData);
    onSave(updatedFormData); // Apelează funcția din componenta părinte
  };

  useEffect(() => {
    // console.log("propsUseEffect", contracts);
    if (contracts && contracts.length > 0) {
      const selectedContract = contracts.find(
        (contract) => contract.idContract === idContract
      );
      // console.log("Selected Contract:", selectedContract);
      setFormData((prevFormData) => ({
        ...prevFormData,
        dataConstatare:
          selectedContract.dataConstatare || prevFormData.dataConstatare,
        numarDosar: selectedContract.nrDosar || prevFormData.numarDosar,
        asigurator: selectedContract.asigurator || prevFormData.asigurator,
        inspector: selectedContract.inspector || prevFormData.inspector,
        conducatorAuto:
          selectedContract.conducatorAuto || prevFormData.conducatorAuto,
        conducatorAutoCi:
          selectedContract.conducatorAutoCi || prevFormData.conducatorAutoCi,
        conducatorAutoTelefon:
          selectedContract.conducatorAutoTelefon ||
          prevFormData.conducatorAutoTelefon,
        pozeActe: selectedContract.pozeActe || prevFormData.pozeActe,
        pozeMasina: selectedContract.pozeMasina || prevFormData.pozeMasina,
        pozeEveniment:
          selectedContract.pozeEveniment || prevFormData.pozeEveniment
      }));
    }
  }, [idContract, contracts]);

  // console.log("props contractsDetails", contracts);
  // console.log("props imagesList", imagesList);
  // console.log("formData", formData);

  return (
    <>
      <Table className="table-body">
        <TableHead>
          <TableRow>
            <TableCell>
              <TextField
                label="Data Constatare"
                type="datetime-local"
                value={
                  formData.dataConstatare
                    ? formatLocalDateTime(formData.dataConstatare)
                    : ""
                }
                onChange={(e) => handleInputChange(e, "dataConstatare")}
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
              />
            </TableCell>
            <TableCell>
              <TextField
                label="Numar Dosar"
                value={formData.numarDosar}
                onChange={(e) => handleInputChange(e, "numarDosar")}
                fullWidth
              />
            </TableCell>
            <TableCell>
              <FormControl fullWidth>
                <InputLabel>Asigurator</InputLabel>
                <Select
                  value={formData.asigurator || ""} // Setează valoarea inițială a selectului
                  onChange={(e) => handleInputChange(e, "asigurator")}
                  label="Asigurator"
                >
                  {insurersList.map((insurer) => (
                    <MenuItem
                      key={insurer.idAsigurator}
                      value={insurer.idAsigurator} // Valoarea selectată va fi idAsigurator
                    >
                      {insurer.denumireAsigurator}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </TableCell>
            <TableCell>
              <TextField
                label="Inspector"
                value={formData.inspector}
                onChange={(e) => handleInputChange(e, "inspector")}
                fullWidth
              />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableHead>
          <TableRow>
            <TableCell>
              <TextField
                label="Nume si prenume conducator auto"
                value={formData.conducatorAuto}
                onChange={(e) => handleInputChange(e, "conducatorAuto")}
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
              />
            </TableCell>
            <TableCell>
              <TextField
                label="CI conducator auto"
                value={formData.conducatorAutoCi}
                onChange={(e) => handleInputChange(e, "conducatorAutoCi")}
                fullWidth
              />
            </TableCell>
            <TableCell>
              <TextField
                label="Telefon conducator auto"
                value={formData.conducatorAutoTelefon}
                onChange={(e) => handleInputChange(e, "conducatorAutoTelefon")}
                fullWidth
              />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableHead>
          <TableRow>
            <TableCell align="center" style={{ fontWeight: "bold" }}>
              POZE ACTE VATAMAT
            </TableCell>
            <TableCell align="center" style={{ fontWeight: "bold" }}>
              POZE ACTE VINOVAT
            </TableCell>
            <TableCell align="center" style={{ fontWeight: "bold" }}>
              POZE MASINA
            </TableCell>
            <TableCell align="center" style={{ fontWeight: "bold" }}>
              POZE EVENIMENT
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            {/* POZE PERSOANA VATAMATA */}
            <TableCell style={{ verticalAlign: "top" }}>
              {Object.keys(formData.pozeActe)
                .slice(0, 5)
                .map((key) => {
                  const foundImage = Array.isArray(imagesList[idContract])
                    ? imagesList[idContract].find((img) =>
                        img.denumire
                          .toLowerCase()
                          .startsWith(`${idContract}-${key}`.toLowerCase())
                      )
                    : null;

                  return (
                    <div key={key} style={{ marginBottom: "7px" }}>
                      <Card
                        sx={{
                          padding: "10px",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center"
                        }}
                      >
                        <Typography
                          variant="subtitle2"
                          gutterBottom
                          align="center"
                        >
                          {key.replace("_", " ").toUpperCase()}
                        </Typography>
                        {formData.pozeActe[key] &&
                        formData.pozeActe[key].file ? (
                          <CardMedia
                            component="img"
                            image={formData.pozeActe[key].previewUrl}
                            alt={key}
                            sx={{
                              width: 100,
                              height: 100,
                              objectFit: "cover",
                              cursor: "pointer",
                              marginBottom: "10px"
                            }}
                            onClick={() =>
                              handleImageClick(
                                formData.pozeActe[key].previewUrl
                              )
                            }
                          />
                        ) : foundImage ? (
                          <CardMedia
                            component="img"
                            image={`${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_FILE_UPLOAD_PATH}/${foundImage.denumire}`}
                            alt={key}
                            sx={{
                              width: 100,
                              height: 100,
                              objectFit: "cover",
                              cursor: "pointer",
                              marginBottom: "10px"
                            }}
                            onClick={() =>
                              handleImageClick(
                                `${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_FILE_UPLOAD_PATH}/${foundImage.denumire}`
                              )
                            }
                          />
                        ) : (
                          <Button
                            variant="outlined"
                            component="label"
                            startIcon={<UploadFileIcon />}
                          >
                            Alege fișierul
                            <input
                              type="file"
                              hidden
                              onChange={(e) =>
                                handlePozeChange(e, key, idContract)
                              }
                            />
                          </Button>
                        )}
                        {(formData.pozeActe[key] &&
                          formData.pozeActe[key].file) ||
                        foundImage ? (
                          <Button
                            onClick={() => handleImageDelete(key, "pozeActe")}
                            startIcon={<DeleteIcon />}
                            variant="contained"
                            color="error"
                            sx={{ marginTop: "10px" }}
                          >
                            Șterge
                          </Button>
                        ) : null}
                      </Card>
                    </div>
                  );
                })}
            </TableCell>

            {/* POZE PERSOANA VINOVATA */}
            <TableCell style={{ verticalAlign: "top" }}>
              {Object.keys(formData.pozeActe)
                .slice(5)
                .map((key) => {
                  const foundImage = Array.isArray(imagesList[idContract])
                    ? imagesList[idContract].find((img) =>
                        img.denumire
                          .toLowerCase()
                          .startsWith(`${idContract}-${key}`.toLowerCase())
                      )
                    : null;

                  return (
                    <div key={key} style={{ marginBottom: "7px" }}>
                      <Card
                        sx={{
                          padding: "10px",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center"
                        }}
                      >
                        <Typography
                          variant="subtitle2"
                          gutterBottom
                          align="center"
                        >
                          {key.replace("_", " ").toUpperCase()}
                        </Typography>
                        {formData.pozeActe[key] &&
                        formData.pozeActe[key].file ? (
                          <CardMedia
                            component="img"
                            image={formData.pozeActe[key].previewUrl}
                            alt={key}
                            sx={{
                              width: 100,
                              height: 100,
                              objectFit: "cover",
                              cursor: "pointer",
                              marginBottom: "10px"
                            }}
                            onClick={() =>
                              handleImageClick(
                                formData.pozeActe[key].previewUrl
                              )
                            }
                          />
                        ) : foundImage ? (
                          <CardMedia
                            component="img"
                            image={`${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_FILE_UPLOAD_PATH}/${foundImage.denumire}`}
                            alt={key}
                            sx={{
                              width: 100,
                              height: 100,
                              objectFit: "cover",
                              cursor: "pointer",
                              marginBottom: "10px"
                            }}
                            onClick={() =>
                              handleImageClick(
                                `${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_FILE_UPLOAD_PATH}/${foundImage.denumire}`
                              )
                            }
                          />
                        ) : (
                          <Button
                            variant="outlined"
                            component="label"
                            startIcon={<UploadFileIcon />}
                          >
                            Alege fișierul
                            <input
                              type="file"
                              hidden
                              onChange={(e) =>
                                handlePozeChange(e, key, idContract)
                              }
                            />
                          </Button>
                        )}
                        {(formData.pozeActe[key] &&
                          formData.pozeActe[key].file) ||
                        foundImage ? (
                          <Button
                            onClick={() => handleImageDelete(key, "pozeActe")}
                            startIcon={<DeleteIcon />}
                            variant="contained"
                            color="error"
                            sx={{ marginTop: "10px" }}
                          >
                            Șterge
                          </Button>
                        ) : null}
                      </Card>
                    </div>
                  );
                })}
            </TableCell>

            {/* POZE MASINA */}
            <TableCell style={{ verticalAlign: "top" }}>
              {Object.keys(formData.pozeMasina).map((key) => {
                const foundImage = Array.isArray(imagesList[idContract])
                  ? imagesList[idContract].find((img) =>
                      img.denumire
                        .toLowerCase()
                        .startsWith(
                          `${idContract}-pozeMasina_${key}`.toLowerCase()
                        )
                    )
                  : null;
                return (
                  <div key={key} style={{ marginBottom: "7px" }}>
                    <Card
                      sx={{
                        padding: "10px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center"
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        gutterBottom
                        align="center"
                      >
                        {key.replace("_", " ").toUpperCase()}
                      </Typography>
                      {formData.pozeMasina[key] &&
                      formData.pozeMasina[key].file ? (
                        <CardMedia
                          component="img"
                          image={formData.pozeMasina[key].previewUrl}
                          alt={key}
                          sx={{
                            width: 100,
                            height: 100,
                            objectFit: "cover",
                            cursor: "pointer",
                            marginBottom: "10px"
                          }}
                          onClick={() =>
                            handleImageClick(
                              formData.pozeMasina[key].previewUrl
                            )
                          }
                        />
                      ) : foundImage ? (
                        <CardMedia
                          component="img"
                          image={`${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_FILE_UPLOAD_PATH}/${foundImage.denumire}`}
                          alt={key}
                          sx={{
                            width: 100,
                            height: 100,
                            objectFit: "cover",
                            cursor: "pointer",
                            marginBottom: "10px"
                          }}
                          onClick={() =>
                            handleImageClick(
                              `${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_FILE_UPLOAD_PATH}/${foundImage.denumire}`
                            )
                          }
                        />
                      ) : (
                        <Button
                          variant="outlined"
                          component="label"
                          startIcon={<UploadFileIcon />}
                        >
                          Alege fișierul
                          <input
                            type="file"
                            hidden
                            onChange={(e) =>
                              handleMasinaEvenimentChange(
                                e,
                                "pozeMasina",
                                key,
                                idContract
                              )
                            }
                          />
                        </Button>
                      )}
                      {(formData.pozeMasina[key] &&
                        formData.pozeMasina[key].file) ||
                      foundImage ? (
                        <Button
                          onClick={() => handleImageDelete(key, "pozeMasina")}
                          startIcon={<DeleteIcon />}
                          variant="contained"
                          color="error"
                          sx={{ marginTop: "10px" }}
                        >
                          Șterge
                        </Button>
                      ) : null}
                    </Card>
                  </div>
                );
              })}
            </TableCell>

            {/* POZE EVENIMENT */}
            <TableCell style={{ verticalAlign: "top" }}>
              {formData.pozeEveniment.map((poza, index) => {
                const foundImage = Array.isArray(imagesList[idContract])
                  ? imagesList[idContract].find((img) =>
                      img.denumire
                        .toLowerCase()
                        .startsWith(
                          `${idContract}-pozaEveniment_${index}`.toLowerCase()
                        )
                    )
                  : null;
                return (
                  <div key={index} style={{ marginBottom: "7px" }}>
                    <Card
                      sx={{
                        padding: "10px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center"
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        gutterBottom
                        align="center"
                      >
                        {`Poza eveniment ${index + 1}`}
                      </Typography>
                      {foundImage ? (
                        <CardMedia
                          component="img"
                          image={`${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_FILE_UPLOAD_PATH}/${foundImage.denumire}`}
                          alt={`Eveniment ${index}`}
                          sx={{
                            width: 100,
                            height: 100,
                            objectFit: "cover",
                            cursor: "pointer",
                            marginBottom: "10px"
                          }}
                          onClick={() =>
                            handleImageClick(
                              `${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_FILE_UPLOAD_PATH}/${foundImage.denumire}`
                            )
                          }
                        />
                      ) : poza && poza.file ? (
                        <CardMedia
                          component="img"
                          image={poza.previewUrl}
                          alt={`Eveniment ${index}`}
                          sx={{
                            width: 100,
                            height: 100,
                            objectFit: "cover",
                            cursor: "pointer",
                            marginBottom: "10px"
                          }}
                          onClick={() => handleImageClick(poza.previewUrl)}
                        />
                      ) : (
                        <Button
                          variant="outlined"
                          component="label"
                          startIcon={<UploadFileIcon />}
                        >
                          Alege fișierul
                          <input
                            type="file"
                            accept="image/*"
                            hidden
                            onChange={(e) =>
                              handleMasinaEvenimentChange(
                                e,
                                "pozeEveniment",
                                index,
                                idContract
                              )
                            }
                          />
                        </Button>
                      )}
                      {((poza && poza.file) || foundImage) && (
                        <Button
                          onClick={() =>
                            handleImageDelete(index, "pozeEveniment")
                          }
                          startIcon={<DeleteIcon />}
                          variant="contained"
                          color="error"
                          sx={{ marginTop: "10px" }}
                        >
                          Șterge
                        </Button>
                      )}
                    </Card>
                  </div>
                );
              })}
              <div style={{ textAlign: "center" }}>
                <Button
                  variant="contained"
                  onClick={handleAddPhoto}
                  startIcon={<UploadFileIcon />}
                  color="success"
                >
                  Adaugă Foto
                </Button>
              </div>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Button
        variant="contained"
        color="primary"
        onClick={handleSave}
        sx={{ margin: "16px" }}
      >
        Salvează
      </Button>
      {/* <Button variant="outlined" onClick={handleReset} color="secondary">
        Resetare
      </Button> */}

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Imagine</DialogTitle>
        <DialogContent>
          {selectedImage && (
            <CardMedia
              component="img"
              image={selectedImage}
              alt="Selected"
              sx={{ width: 400 }}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDialog}
            color="error"
            startIcon={<CloseIcon />}
          >
            Închide
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ContractDetails;
