import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import SecurityIcon from "@mui/icons-material/Security";
import Grid from "@mui/material/Grid";
import Axios from "axios";

function insuranceAddForm() {
  const [open, setOpen] = useState(false);
  const [inputFields, setInputFields] = useState({
    denumireAsigurator: "",
    societateAsigurator: "",
    adresaAsigurator: "",
    localitateAsigurator: "",
    judetAsigurator: "",
    cuiAsigurator: "",
    nrcAsigurator: ""
  });
  const [errors, setErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const validateValues = (inputValues) => {
    let errors = {};
    if (inputValues.denumireAsigurator.length === 0) {
      errors.denumireAsigurator = "Completeaza denumirea asiguratorului";
    }
    if (inputValues.societateAsigurator.length === 0) {
      errors.societateAsigurator = "Completeaza societatea asiguratorului";
    }
    if (inputValues.adresaAsigurator.length === 0) {
      errors.adresaAsigurator = "Completeaza adresa asiguratorului";
    }
    if (inputValues.localitateAsigurator.length === 0) {
      errors.localitateAsigurator = "Completeaza localitatea";
    }
    if (inputValues.judetAsigurator.length === 0) {
      errors.judetAsigurator = "Completeaza judetul";
    }
    if (inputValues.cuiAsigurator.length === 0) {
      errors.cuiAsigurator = "Completeaza CUI-ul asiguratorului";
    }
    if (inputValues.nrcAsigurator.length === 0) {
      errors.nrcAsigurator = "Completeaza numarul de inregistrare";
    }
    return errors;
  };

  const handleChange = (e) => {
    setInputFields({ ...inputFields, [e.target.name]: e.target.value });
  };

  const handleSubmit = (p) => {
    p.preventDefault();
    setErrors(validateValues(inputFields));
    setSubmitting(true);
  };

  const finishSubmit = async () => {
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const insurerData = { ...inputFields };
    console.log({ insurerData: insurerData });
    await Axios.post(`${BASE_URL}/addInsurer`, insurerData).then(() => {
      handleClose();
    });

    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  useEffect(() => {
    if (Object.keys(errors).length === 0 && submitting) {
      finishSubmit();
    }
  }, [errors]);

  return (
    <>
      <Button
        variant="contained"
        color="success"
        onClick={handleClickOpen}
        style={{ marginTop: "20px" }}
      >
        <SecurityIcon style={{paddingRight:"4px"}}/>
        Adauga asigurator
      </Button>

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle>Adauga asigurator</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              {Object.keys(inputFields).map((field, index) => (
                <Grid item xs={12} sm={6} key={index}>
                  <TextField
                    margin="dense"
                    label={field.replace(/([A-Z])/g, " $1").toUpperCase()}
                    type="text"
                    fullWidth
                    variant="outlined"
                    name={field}
                    value={inputFields[field]}
                    onChange={handleChange}
                    error={Boolean(errors[field])}
                    helperText={errors[field] || ""}
                  />
                </Grid>
              ))}
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Anuleaza
          </Button>
          <Button
            onClick={handleSubmit}
            color="success"
            type="submit"
            variant="contained"
          >
            <SecurityIcon style={{paddingRight:"4px"}}/>
            Adauga asigurator
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default insuranceAddForm;
