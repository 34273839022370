import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Axios from "axios";
import "./index.css"; // Fișierul CSS pentru stiluri personalizate

function ChangePassComponent() {
  const [inputFields, setInputFields] = useState({
    oldPassword: "",
    newPassword1: "",
    newPassword2: "",
    isOldPasswordWrong: ""
  });
  const [errors, setErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);

  const validateValues = (inputValues) => {
    let errors = {};
    if (inputValues.oldPassword.length === 0) {
      errors.oldPassword = "Parola actuală este obligatorie!";
    }
    if (inputValues.newPassword1.length === 0) {
      errors.newPassword1 = "Parola nouă este obligatorie!";
    }
    if (inputValues.newPassword2.length === 0) {
      errors.newPassword2 = "Confirmarea parolei este obligatorie!";
    }
    if (inputValues.newPassword1 !== inputValues.newPassword2) {
      errors.notEqual = "Parolele nu coincid!";
    }
    if (errors.isOldPasswordWrong === "notOk") {
      errors.isOldPasswordWrong = "Parola actuală nu este corectă!";
    }
    return errors;
  };

  const userStorage = sessionStorage.getItem("user");
  const user = userStorage ? JSON.parse(userStorage) : null;

  const verifyOldPass = async () => {
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const userData = {
      oldPassword: inputFields.oldPassword,
      userId: user?.id
    };

    await Axios.post(`${BASE_URL}/verifyOldPass`, userData).then((res) => {
      setErrors({
        ...errors,
        isOldPasswordWrong:
          res.data.id > 0 ? "" : "Parola actuală nu este corectă!"
      });
    });
  };

  const handleChange = (e) => {
    setInputFields({ ...inputFields, [e.target.name]: e.target.value });
  };

  const handleSubmit = (p) => {
    p.preventDefault();
    const validationErrors = validateValues(inputFields);
    setErrors(validationErrors);
    setSubmitting(true);
  };

  const finishSubmit = async () => {
    const BASE_URL = process.env.REACT_APP_BASE_URL;

    const userData = {
      newPassword: inputFields.newPassword1,
      userId: user?.id
    };
    await Axios.post(`${BASE_URL}/changePass`, userData).then(() => {
      setSuccess(true);
    });

    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  useEffect(() => {
    if (Object.keys(errors).length === 0 && submitting) {
      finishSubmit();
    }
  }, [errors]);

  return (
    <div className="change-password-form-container">
      <h2>Schimbă parola</h2>
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3 mt-4" controlId="formBasicOldPass">
          <Form.Label>Parola actuală</Form.Label>
          <Form.Control
            type="password"
            name="oldPassword"
            value={inputFields.oldPassword}
            onChange={handleChange}
            onBlur={verifyOldPass}
            className={errors.oldPassword || errors.isOldPasswordWrong ? "error-border" : ""}
          />
          {errors.oldPassword && (
            <p className="error-message">{errors.oldPassword}</p>
          )}
          {errors.isOldPasswordWrong && (
            <p className="error-message">{errors.isOldPasswordWrong}</p>
          )}
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicNewPassword1">
          <Form.Label>Parola nouă</Form.Label>
          <Form.Control
            type="password"
            name="newPassword1"
            value={inputFields.newPassword1}
            onChange={handleChange}
            className={errors.newPassword1 ? "error-border" : ""}
          />
          {errors.newPassword1 && (
            <p className="error-message">{errors.newPassword1}</p>
          )}
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicNewPassword2">
          <Form.Label>Confirmă parola nouă</Form.Label>
          <Form.Control
            type="password"
            name="newPassword2"
            value={inputFields.newPassword2}
            onChange={handleChange}
            className={errors.newPassword2 || errors.notEqual ? "error-border" : ""}
          />
          {errors.newPassword2 && (
            <p className="error-message">{errors.newPassword2}</p>
          )}
          {errors.notEqual && <p className="error-message">{errors.notEqual}</p>}
        </Form.Group>

        {success ? (
          <span className="success-message">Parola a fost schimbată cu succes! ✓</span>
        ) : (
          <Button variant="primary" type="submit" className="submit-button">
            Schimbă parola
          </Button>
        )}
      </Form>
    </div>
  );
}

export default ChangePassComponent;
