import React, { useState, useEffect } from "react";
import Axios from "axios";
import {
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid
} from "@mui/material";
import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";

const AddLocationForm = ({handleChangeData}) => {
  const [inputFields, setInputFields] = useState({
    adresa: "",
    localitate: "",
    judet: "",
    telefon: ""
  });
  const [errors, setErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true); // Deschide modalul
  const handleClose = () => setOpen(false); // Închide modalul

  const validateValues = (inputValues) => {
    let errors = {};
    if (inputValues.localitate.length === 0) {
      errors.localitate = "Completează localitatea";
    }
    if (inputValues.adresa.length === 0) {
      errors.adresa = "Completează adresa";
    }
    if (inputValues.judet.length === 0) {
      errors.judet = "Completează județul";
    }
    if (inputValues.telefon.length === 0) {
      errors.telefon = "Completează numărul de telefon";
    }
    return errors;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputFields((prevFields) => ({
      ...prevFields,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Locație adăugată:", inputFields);
    setErrors(validateValues(inputFields));
    setSubmitting(true);
  };

  const finishSubmit = async () => {
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    try {
      await Axios.post(`${BASE_URL}/addLocation`, inputFields);
    //   console.log("Response", response);
      handleClose();
    } catch (error) {
      console.error("Error", error);
    }
  };

  useEffect(() => {
    if (Object.keys(errors).length === 0 && submitting) {
      finishSubmit();
      handleChangeData();
      setInputFields({
        adresa: "",
        localitate: "",
        judet: "",
        telefon: ""
      });
    }
  }, [errors]);

  return (
    <>
      <Button
        variant="contained"
        color="success"
        onClick={handleOpen}
        style={{ marginTop: "20px" }}
      >
        <AddLocationAltIcon style={{ paddingRight: "4px" }} />
        Adauga locatie
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle>Adauga locatie</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <TextField
                  label="Localitate"
                  name="localitate"
                  value={inputFields.localitate}
                  onChange={handleChange}
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  label="Adresă"
                  name="adresa"
                  value={inputFields.adresa}
                  onChange={handleChange}
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  label="Judet"
                  name="judet"
                  value={inputFields.judet}
                  onChange={handleChange}
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  label="Telefon"
                  name="telefon"
                  value={inputFields.telefon}
                  onChange={handleChange}
                  required
                  fullWidth
                  inputProps={{ maxLength: 10, pattern: "[0-9]{10}" }}
                  helperText="Introduceți un număr de telefon valid (10 cifre)."
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Anuleaza
            </Button>
            <Button
              onClick={handleSubmit}
              color="success"
              type="submit"
              variant="contained"
            >
              <AddLocationAltIcon style={{ paddingRight: "4px" }} />
              Adaugă locația
            </Button>
          </DialogActions>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddLocationForm;
