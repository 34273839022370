import React from "react";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from "@mui/material";
import {formatDate} from "../../utils/dateFormat";

const LocationsList = ({ locationsList }) => {
  return (
    <>
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="insurers table">
        <TableHead sx={{ backgroundColor: "#000000" }}>
          <TableRow>
            <TableCell sx={{ color: "#ffffff" }}>#</TableCell>
            <TableCell sx={{ color: "#ffffff" }}>Localitatea</TableCell>
            <TableCell sx={{ color: "#ffffff" }}>Adresa</TableCell>
            <TableCell sx={{ color: "#ffffff" }}>Judetul</TableCell>
            <TableCell sx={{ color: "#ffffff" }}>Telefonul</TableCell>
            <TableCell sx={{ color: "#ffffff" }}>Data Adaugare</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {locationsList.map((location, index) => (
            <TableRow
              key={location.idLocatie}
              sx={{ "&:nth-of-type(odd)": { backgroundColor: "#f5f5f5" } }}
            >
              <TableCell component="th" scope="row">
                {index + 1}
              </TableCell>
              <TableCell>{location.localitatea}</TableCell>
              <TableCell>{location.adresa}</TableCell>
              <TableCell>{location.judet}</TableCell>
              <TableCell>{location.telefon}</TableCell>
              <TableCell>{formatDate(location.dataModificare)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </>
  );
};

export default LocationsList;
