import React from "react";

import Header from "../../navigation/header";
import Title from "../../navigation/title";
// import { checkIsLoggedIn } from "../../auth/login/Login";
import CommissionsList from "./CommissionsList";

import "./index.css";

const Commissions = () => {
  return (
    <div>
      <Header />
      <Title title="Lista comisioane" />
      <CommissionsList />
    </div>
  );
};

export default Commissions;
