import React, { useState, useEffect } from "react";
import Axios from "axios";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const InsurersList = () => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [listInsurers, setListInsurers] = useState([]);

  useEffect(() => {
    // Obținerea listei de asiguratori
    Axios.get(`${BASE_URL}/insurersList/`).then((res) => {
      setListInsurers(res.data);
    });
  }, []);

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="insurers table">
        <TableHead sx={{ backgroundColor: "#000000" }}>
          <TableRow>
            <TableCell sx={{ color: "#ffffff" }}>#</TableCell>
            <TableCell sx={{ color: "#ffffff" }}>Denumire Asigurator</TableCell>
            <TableCell sx={{ color: "#ffffff" }}>Societate</TableCell>
            <TableCell sx={{ color: "#ffffff" }}>Adresa</TableCell>
            <TableCell sx={{ color: "#ffffff" }}>Localitate</TableCell>
            <TableCell sx={{ color: "#ffffff" }}>Judet</TableCell>
            <TableCell sx={{ color: "#ffffff" }}>CUI</TableCell>
            <TableCell sx={{ color: "#ffffff" }}>Nr. Reg. Com.</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {listInsurers.map((insurer, index) => (
            <TableRow key={insurer.idAsigurator} sx={{ "&:nth-of-type(odd)": { backgroundColor: "#f5f5f5" } }}>
              <TableCell component="th" scope="row">{index + 1}</TableCell>
              <TableCell>{insurer.denumireAsigurator}</TableCell>
              <TableCell>{insurer.societateAsigurator}</TableCell>
              <TableCell>{insurer.adresaAsigurator}</TableCell>
              <TableCell>{insurer.localitateAsigurator}</TableCell>
              <TableCell>{insurer.judetAsigurator}</TableCell>
              <TableCell>{insurer.cuiAsigurator}</TableCell>
              <TableCell>{insurer.nrcAsigurator}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default InsurersList;
