import React, { useState, useEffect } from "react";
import Axios from "axios";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import EditCarModalButton from "./modal/editCarsListModal";
import DeleteButton from "./modal/deleteButtons";

const AddCarList = () => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [listCars, setListCars] = useState([]);
  const [dataFromChild, setDataFromChild] = useState(0);

  const getDataFromChildEdit = (data) => {
    setDataFromChild(data);
  };

  const getDataFromChildDelete = (data) => {
    setDataFromChild(data);
  };

  useEffect(() => {
    Axios.get(`${BASE_URL}/carList/`).then((res) => {
      setListCars(res.data);
    });
  }, [dataFromChild]);

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="cars table">
          <TableHead sx={{ backgroundColor: "#000000" }}>
            <TableRow>
              <TableCell sx={{ color: "#ffffff" }}>#</TableCell>
              <TableCell sx={{ color: "#ffffff" }}>Numar Auto</TableCell>
              <TableCell sx={{ color: "#ffffff" }}>Categoria</TableCell>
              <TableCell sx={{ color: "#ffffff" }}>Marca</TableCell>
              <TableCell sx={{ color: "#ffffff" }}>Model</TableCell>
              <TableCell sx={{ color: "#ffffff" }}>Culoare</TableCell>
              <TableCell sx={{ color: "#ffffff" }}>Serie Sasiu</TableCell>
              <TableCell sx={{ color: "#ffffff" }}>
                Seria Carte Identitate
              </TableCell>
              <TableCell sx={{ color: "#ffffff" }}>Cilindree</TableCell>
              <TableCell sx={{ color: "#ffffff" }}>MMA</TableCell>
              <TableCell sx={{ color: "#ffffff" }}>An Fabricatie</TableCell>
              <TableCell sx={{ color: "#ffffff" }}>2-4 Zile</TableCell>
              <TableCell sx={{ color: "#ffffff" }}>5-8 Zile</TableCell>
              <TableCell sx={{ color: "#ffffff" }}>9-14 Zile</TableCell>
              <TableCell sx={{ color: "#ffffff" }}>15-29 Zile</TableCell>
              <TableCell sx={{ color: "#ffffff" }}>30+ Zile</TableCell>
              <TableCell sx={{ color: "#ffffff" }}>Actiuni</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {listCars.map((item, index) => (
              <TableRow
                key={item.carId}
                sx={{ "&:nth-of-type(odd)": { backgroundColor: "#f5f5f5" } }}
              >
                <TableCell component="th" scope="row">
                  {index + 1}
                </TableCell>
                <TableCell>{item.numarAuto}</TableCell>
                <TableCell>{item.categoria}</TableCell>
                <TableCell>{item.marca}</TableCell>
                <TableCell>{item.model}</TableCell>
                <TableCell>{item.culoare}</TableCell>
                <TableCell>{item.vin}</TableCell>
                <TableCell>{item.civ}</TableCell>
                <TableCell>{item.cilindree}</TableCell>
                <TableCell>{item.mma}</TableCell>
                <TableCell>{item.anulFabricatiei}</TableCell>
                <TableCell>{item.tarif1}</TableCell>
                <TableCell>{item.tarif2}</TableCell>
                <TableCell>{item.tarif3}</TableCell>
                <TableCell>{item.tarif4}</TableCell>
                <TableCell>{item.tarif5}</TableCell>
                <TableCell>
                  <Grid container spacing={1}>
                    <Grid item>
                      <EditCarModalButton
                        carId={item.carId}
                        nrAuto={item.numarAuto}
                        categoria={item.categoria}
                        marca={item.marca}
                        model={item.model}
                        culoare={item.culoare}
                        vin={item.vin}
                        civ={item.civ}
                        cilindree={item.cilindree}
                        mma={item.mma}
                        an={item.anulFabricatiei}
                        tarif1={item.tarif1}
                        tarif2={item.tarif2}
                        tarif3={item.tarif3}
                        tarif4={item.tarif4}
                        tarif5={item.tarif5}
                        onSubmitedEdit={getDataFromChildEdit}
                      />
                    </Grid>
                    <Grid item>
                      <DeleteButton
                        carId={item.carId}
                        nrAuto={item.numarAuto}
                        onSubmitedDelete={getDataFromChildDelete}
                      />
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Paper sx={{ padding: 2, marginTop: 3 }}>
        <Grid container spacing={2} justifyContent="center">
          <Grid item></Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default AddCarList;
