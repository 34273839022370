import React from "react";
import { Modal, Box, Button } from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const InvoiceDatePicker = ({ open, onClose, selectedDate, setSelectedDate, onGenerateInvoice }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: "10px"
        }}
      >
        <h3>Selectează data facturii</h3>
        <DatePicker
          selected={selectedDate}
          onChange={(date) => setSelectedDate(date)} // Actualizează data
          dateFormat="dd/MM/yyyy"
          placeholderText="Selectează data"
          isClearable
          showPopperArrow={false}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={onGenerateInvoice}
          disabled={!selectedDate}
          style={{ marginTop: "20px" }}
        >
          Generează Factura
        </Button>
      </Box>
    </Modal>
  );
};

export default InvoiceDatePicker;
