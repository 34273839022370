import React, { useState, useEffect } from "react";
import Axios from "axios";
import Header from "../../navigation/header";
import Title from "../../navigation/title";
import AddLocationForm from "./addLocation";
import LocationsList from "./locationsList";

const Locations = () => {
  const [locationsList, setLocationsList] = useState([]);
  const [counter, setCounter] = useState(0);

  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const handleChangeData = () => {
    setCounter((prev) => prev + 1);
  };

  useEffect(() => {
    // Obținerea listei de asiguratori
    Axios.get(`${BASE_URL}/locationsList/`).then((res) => {
      setLocationsList(res.data);
    });
  }, [counter]);
  //   console.log({ locationsList: locationsList });

  return (
    <div>
      <Header />
      <Title title="Lista locatii" />
      <div style={{ marginBottom: "20px" }}>
        <AddLocationForm handleChangeData={handleChangeData} />
      </div>
      <div className="table-responsive">
        <LocationsList locationsList={locationsList} />
      </div>
    </div>
  );
};

export default Locations;
