import React, { useState, useEffect } from "react";
import {
  TextField,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import axios from "axios";
import moment from "moment";
import "dayjs/locale/ro";
dayjs.locale("ro");

import "./index.css";

export const NewDateFormat = (yourDate) => {
  return moment(yourDate).format("DD-MM-YYYY");
};

const ContractsList = () => {
  const [dataInceput, setDataInceput] = useState(null);
  const [dataSfarsit, setDataSfarsit] = useState(null);
  const [agentsAdminCommissions, setAgentsAdminCommissions] = useState([]);
  const [managersCommissions, setManagersCommissions] = useState([]);
  const [managersList, setManagersList] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [idRoleState, setIdRoleState] = useState(null);
  const [userIdFromStorage, setUserIdFromStorage] = useState(null);

  // const [loading, setLoading] = useState(false);

  const BASE_URL = process.env.REACT_APP_BASE_URL;

  // Setăm valorile inițiale pentru date
  useEffect(() => {
    setDataInceput(dayjs().startOf("month"));
    setDataSfarsit(dayjs().endOf("month"));
  }, []);

  const getContractsList = async () => {
    let idRole, idUser;
    const userStorage = sessionStorage.getItem("user");

    if (userStorage) {
      const userData = JSON.parse(userStorage);
      idRole = userData.idRole;
      idUser = userData.id;
      setIdRoleState(idRole);
      setUserIdFromStorage(idUser);
    } else {
      console.log("Nu există date stocate în `sessionStorage` pentru userData");
      return;
    }

    try {
      // Formatează datele în funcție de selecțiile utilizatorului
      const formattedDataInceput = dataInceput
        ? `${dataInceput.format("YYYY-MM-DD")} 00:00:00`
        : `${dayjs().startOf("month").format("YYYY-MM-DD")} 00:00:00`;

      const formattedDataSfarsit = dataSfarsit
        ? `${dataSfarsit.format("YYYY-MM-DD")} 23:59:59`
        : `${dayjs().endOf("month").format("YYYY-MM-DD")} 23:59:59`;

      const requestData = {
        dataInceput: formattedDataInceput,
        dataSfarsit: formattedDataSfarsit,
        idRole: idRole,
        idUser: idUser
      };

      // setLoading(true);

      const agentsAdminCommissionResponse = await axios.get(
        `${BASE_URL}/getAgentsAdminCommissionsList`,
        { params: requestData }
      );
      const agentsAdminCommissions = agentsAdminCommissionResponse.data;
      setAgentsAdminCommissions(agentsAdminCommissions);
      console.log("agentsAdminCommissions", agentsAdminCommissions);

      const managersResponse = await axios.get(
        `${BASE_URL}/getManagersCommissionsList`,
        { params: requestData }
      );
      const managersCommissionsList = managersResponse.data;
      setManagersCommissions(managersCommissionsList);
      // console.log("managersCommissionsList", managersCommissionsList);

      const usersResponse = await axios.get(`${BASE_URL}/getUsersList`);
      const usersList = usersResponse.data;
      setUsersList(usersList);
      console.log("usersList", usersList);

      const getManagersList = await axios.get(`${BASE_URL}/getManagersList`);
      const managersList = getManagersList.data;
      setManagersList(managersList);

      // setLoading(false);
    } catch (error) {
      console.error("Eroare la obținerea datelor:", error);
      // setLoading(false);
    }
  };

  // Grupare după idContract
  const groupedCommissions = managersCommissions.reduce((acc, manager) => {
    const contractId = manager.idContract;

    // Verifică dacă contractul există deja în array-ul final
    if (!acc[contractId]) {
      acc[contractId] = {
        idContract: contractId,
        managersCommissions: [],
        agentsAdminCommissions: []
      };
    }
    // Adaugă comisionul managerului la array-ul corespunzător
    acc[contractId].managersCommissions.push(manager);

    return acc;
  }, {});

  // Adaugă și comisioanele agenților
  agentsAdminCommissions.forEach((agent) => {
    const contractId = agent.idContract;

    if (!groupedCommissions[contractId]) {
      groupedCommissions[contractId] = {
        idContract: contractId,
        managersCommissions: [],
        agentsAdminCommissions: []
      };
    }
    groupedCommissions[contractId].agentsAdminCommissions.push(agent);
  });

  // Transformă obiectul într-un array
  const agentsAdminManagersCommissionsByIdContract =
    Object.values(groupedCommissions);

  console.log({ NEwARRAY: agentsAdminManagersCommissionsByIdContract });

  useEffect(() => {
    getContractsList();
  }, [dataInceput, dataSfarsit]); // Reîncărcăm lista de contracte atunci când se schimbă intervalul de date

  console.log("usersList", usersList);
  console.log("idRoleState", idRoleState);
  console.log("managersList", managersList);

  return (
    <div>
      <Grid container spacing={3} alignItems="center" justifyContent="center">
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ro">
            <DatePicker
              label="Data Început"
              value={dataInceput}
              onChange={(newValue) => setDataInceput(newValue)}
              inputFormat="DD/MM/YYYY"
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ro">
            <DatePicker
              label="Data Sfârșit"
              value={dataSfarsit}
              onChange={(newValue) => setDataSfarsit(newValue)}
              inputFormat="DD/MM/YYYY"
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>

      {/* Tabelul pentru comisioane */}
      <TableContainer component={Paper} className={idRoleState === 1 ? "table-admin" : "table-user"}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Contract ID</TableCell>
              {idRoleState === 1 && <TableCell align="center">Total</TableCell>}
              <TableCell align="center">Comision Agent</TableCell>
              {idRoleState === 1 && (
                <TableCell align="center">Comision Administrator </TableCell>
              )}
              {managersList.length > 0 &&
                managersList.map((manager, index) => (
                  <>
                    {/* Dacă idRoleState este 1, afișează toți managerii */}
                    {idRoleState === 1 && (
                      <TableCell key={index} align="center">
                        {manager.nume} {manager.prenume}
                      </TableCell>
                    )}

                    {/* Dacă idRoleState nu este 1, afișează doar managerul cu userIdFromStorage */}
                    {idRoleState !== 1 &&
                      manager.id === userIdFromStorage && (
                        <TableCell key={index} align="center">
                          {manager.username}
                        </TableCell>
                      )}
                  </>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {agentsAdminManagersCommissionsByIdContract.map((contract) => {
              // Calculăm totalul comisioanelor pentru agent și admin
              const totalagentAdmin = contract.agentsAdminCommissions.reduce(
                (total, commission) =>
                  total + commission.comisionAgent + commission.comisionAdmin,
                0
              );

              // Calculăm totalul comisioanelor pentru manageri
              const totalManagers = contract.managersCommissions.reduce(
                (total, managerCommission) =>
                  total + managerCommission.comision,
                0
              );

              return (
                <TableRow key={contract.idContract}>
                  {/* Afișează ID-ul contractului */}
                  <TableCell>{contract.idContract}</TableCell>

                  {/* Afișează totalul comisionului pentru agent și admin */}
                  {idRoleState === 1 && (
                    <TableCell align="center">
                      {totalagentAdmin + totalManagers} RON
                    </TableCell>
                  )}

                  {/* Afișează comisioanele agenților/adminilor */}
                  <TableCell align="center">
                    {contract.agentsAdminCommissions.map((commission) => {
                      const userAg = usersList.find(
                        (user) => user.userId === commission.idAgent
                      );

                      return (
                        <div key={commission.idComision}>
                          {commission.comisionAgent} RON (
                          {userAg
                            ? userAg.nume + " " + userAg.prenume
                            : "Necunoscut"}
                          )
                        </div>
                      );
                    })}
                  </TableCell>
                  {idRoleState === 1 && (
                    <TableCell align="center">
                      {contract.agentsAdminCommissions.map((commission) => (
                      <div key={commission.idComision}>
                        {commission.comisionAdmin} RON (Admin)
                      </div>
                      ))}
                    </TableCell>
                  )}
                  {/* Afișează comisioanele managerilor */}
                  {managersList.map((manager, index) => {
                    const managerCommission = contract.managersCommissions.find(
                      (managerCom) => managerCom.idManager === manager.id
                    );
                    console.log("managerCommission", managerCommission);

                    if (idRoleState === 1) {
                      return (
                        <TableCell key={index} align="center">
                          {managerCommission
                            ? `${managerCommission.comision} RON`
                            : "-"}
                        </TableCell>
                      );
                    }
                    if (
                      idRoleState !== 1 &&
                      manager.id === userIdFromStorage
                    ) {
                      return (
                        <TableCell key={index} align="center">
                          {managerCommission
                            ? `${managerCommission.comision} RON`
                            : "-"}
                        </TableCell>
                      );
                    }

                    // Dacă nu se îndeplinește nicio condiție, nu afișăm nimic
                    return null;
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ContractsList;
