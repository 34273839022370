import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

const DateFilter = (props) => {
  const [inputFields, setInputFields] = useState({
    dataInceput: null,
    dataSfarsit: null
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    // Citim datele din `localStorage` dacă există, altfel setăm date implicite
    const savedDataInceput = localStorage.getItem("dataInceput");
    const savedDataSfarsit = localStorage.getItem("dataSfarsit");

    let dataInceput = savedDataInceput
      ? moment(savedDataInceput, "YYYY-MM-DD").toDate()
      : null;
    let dataSfarsit = savedDataSfarsit
      ? moment(savedDataSfarsit, "YYYY-MM-DD").toDate()
      : null;

    // Dacă nu există valori salvate, setăm prima și ultima zi a lunii curente
    if (!dataInceput || !dataSfarsit) {
      const date = new Date();
      dataInceput = new Date(date.getFullYear(), date.getMonth(), 1);
      dataSfarsit = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    }

    setInputFields({
      dataInceput,
      dataSfarsit
    });

    // Apelăm `getContractsList` cu valorile inițiale de dată
    const formattedDataInceput = moment(dataInceput).format("YYYY-MM-DD");
    const formattedDataSfarsit = moment(dataSfarsit).format("YYYY-MM-DD");
    props.getContractsList(formattedDataInceput, formattedDataSfarsit);
  }, []);

  const validateValues = (inputValues) => {
    let errors = {};
    if (!inputValues.dataInceput) {
      errors.dataInceput = "Data început este obligatorie!";
    }
    if (!inputValues.dataSfarsit) {
      errors.dataSfarsit = "Data sfârșit este obligatorie!";
    }
    if (
      inputValues.dataInceput &&
      inputValues.dataSfarsit &&
      moment(inputValues.dataInceput).isAfter(moment(inputValues.dataSfarsit))
    ) {
      errors.dataInceput = "Data început este mai mare decât data sfârșit!";
    }
    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validateValues(inputFields);
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      const formattedDataInceput = moment(inputFields.dataInceput).format(
        "YYYY-MM-DD"
      );
      const formattedDataSfarsit = moment(inputFields.dataSfarsit).format(
        "YYYY-MM-DD"
      );

      // Salvăm datele formate în `localStorage`
      localStorage.setItem("dataInceput", formattedDataInceput);
      localStorage.setItem("dataSfarsit", formattedDataSfarsit);

      // Apelăm funcția pentru a obține lista contractelor
      props.getContractsList(formattedDataInceput, formattedDataSfarsit);
    }
  };

  const handleChange = (name, date) => {
    setInputFields({
      ...inputFields,
      [name]: date
    });

    // Salvăm data selectată în `localStorage` în formatul `YYYY-MM-DD`
    const formattedDate = moment(date).format("YYYY-MM-DD");
    localStorage.setItem(name, formattedDate);
  };

  return (
    <>
      <div className="filter_contracts-list-form-container mb-4">
        <form onSubmit={handleSubmit} className="form-inline">
          <div className="form-group d-inline-flex align-items-center w-auto mt-1">
            <label className="mr-2 ml-2 ps-2" style={{ width: "150px" }}>
              Data start:
            </label>
            <DatePicker
              selected={inputFields.dataInceput}
              onChange={(date) => handleChange("dataInceput", date)}
              dateFormat="dd-MM-yyyy"
              className="form-control"
              placeholderText="Selectează data de început"
            />
            {errors.dataInceput ? (
              <p className="error">{errors.dataInceput}</p>
            ) : null}
          </div>
          <div className="form-group d-inline-flex align-items-center w-auto mt-1">
            <label className="mr-2 ml-2 ps-2" style={{ width: "150px" }}>
              Data sfarsit:
            </label>
            <DatePicker
              selected={inputFields.dataSfarsit}
              onChange={(date) => handleChange("dataSfarsit", date)}
              dateFormat="dd-MM-yyyy"
              className="form-control"
              placeholderText="Selectează data de sfârșit"
            />
            {errors.dataSfarsit ? (
              <p className="error">{errors.dataSfarsit}</p>
            ) : null}
          </div>
          <div className="form-group d-inline-flex align-items-center w-auto ps-4 mt-1 mb-2">
            <Button variant="outline-secondary" type="submit">
              Caută
            </Button>
          </div>
        </form>
      </div>
    </>
  );
};

export default DateFilter;
