import React, { useState } from "react";
import { Modal, Box, Button, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import noImage from "../../../assets/img/no-image.jpg";
const AddNotaConstatareModal = ({
  open,
  onClose,
  contractId,
  handleChangeDataAdd
}) => {
  const [file, setFile] = useState(null); // Stocăm fișierul ales
  const [previewImage, setPreviewImage] = useState(null);
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const handleSelectImage = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile); // Stochează fișierul ales în starea `file`

      if (selectedFile.type === "application/pdf") {
        // Dacă fișierul este PDF, setează imaginea predefinită pentru PDF
        setPreviewImage(noImage); // Imaginea predefinită pentru PDF
      } else if (selectedFile.type.startsWith("image/")) {
        // Dacă fișierul este o imagine, folosește FileReader pentru a crea preview-ul
        const fileReader = new FileReader();
        fileReader.addEventListener("load", () => {
          setPreviewImage(fileReader.result);
        });
        fileReader.readAsDataURL(selectedFile);
      } else {
        // Dacă fișierul nu este suportat, resetăm preview-ul
        setPreviewImage(null);
      }
    }
  };

  console.log("contractId", contractId);

  const handleUpload = async () => {
    if (file) {
      const formData = new FormData();
      formData.append("notaConstatare", file);
      formData.append("contractId", contractId);

      console.log("FormData before upload:", Array.from(formData.entries()));

      try {
        const response = await fetch(`${BASE_URL}/addNotaConstatare?contractId=${contractId}`, {
          method: "POST",
          body: formData,
          credentials: "include"
        });

        if (response.ok) {
          console.log("Fișierul a fost urcat cu succes!");
          handleChangeDataAdd();
          onClose(); // Închide modalul după upload
        } else {
          // Aici poți gestiona erorile
          console.error("Eroare la urcarea fișierului");
        }
      } catch (error) {
        console.error("Eroare de rețea:", error);
      }
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: "10px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center"
          // position: "relative"
        }}
      >
        <Button
          onClick={onClose}
          sx={{
            position: "absolute",
            top: 10,
            right: 10,
            minWidth: 0,
            padding: 0
          }}
        >
          <CloseIcon />
        </Button>

        <Typography variant="h6" component="h2" gutterBottom>
          Adauga nota de constatare
        </Typography>
        <input
          type="file"
          accept="image/jpeg, image/png, application/pdf"
          onChange={handleSelectImage}
          style={{ marginBottom: "20px", width: "100%" }}
          name="notaConstatare"
          id="notaConstatare"
        />
        {previewImage ? (
          <img
            src={previewImage}
            alt="preview-image"
            style={{ maxWidth: 100, maxHeight: 100 }}
          />
        ) : null}
        <Button
          variant="contained"
          color="primary"
          onClick={handleUpload}
          disabled={!file}
          sx={{ marginBottom: "10px", width: "100%" }}
        >
          Urcă fișier
        </Button>
      </Box>
    </Modal>
  );
};

export default AddNotaConstatareModal;
