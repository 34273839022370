import React, { useState } from "react";
import { Modal, Box, Typography, TextField, Button } from "@mui/material";

// Stiluri pentru modal
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

const AddAmountModal = ({ open, onClose, cashedAmount, contractId }) => {
  const [amount, setAmount] = useState("");

  const handleInputChange = (e) => {
    setAmount(e.target.value);
  };

  const handleSave = () => {
    if (!amount || isNaN(amount)) {
      alert("Introduceți o sumă validă în lei!");
      return;
    }
    cashedAmount(contractId, amount); // Trimitem valoarea introdusă către componenta părinte
    setAmount(""); // Resetăm valoarea locală
    onClose(); // Închidem modalul
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={style}>
        <Typography variant="h6" component="h2" mb={2}>
          Adaugă suma încasată în lei
        </Typography>
        <TextField
          fullWidth
          label="Sumă în lei"
          variant="outlined"
          value={amount}
          onChange={handleInputChange} // Gestionăm schimbarea valorii
          type="text"
          placeholder="Ex: 100.50"
        />
        <Box mt={3} display="flex" justifyContent="space-between">
          <Button variant="contained" color="primary" onClick={handleSave}>
            Salvează
          </Button>
          <Button variant="outlined" color="secondary" onClick={onClose}>
            Anulează
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddAmountModal;
