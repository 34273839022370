import Login, { getStorageUser } from "./auth/login/Login";
import Dashboard from "./members/homepage/dashboard";
import AddCar from "./members/cars/addCar";
import AddUser from "./members/users/addUser";
import ChangePassword from "./auth/login/changePassword";
import RentedCars from "./members/rapoarte/rentedCars";
import Insurers from "./members/insurers/insurers";
import Commissions from "./members/commissions/Commissions";
import Locations from "./members/locations/index";
import ProtectedRoute from "./utils/ProtectedRoute";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

import React, { useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

export const UserContext = React.createContext();

function App() {

  const storageUser = getStorageUser();
  const [user, setUser] = useState(storageUser);

  return (
    <div className="App">
      <BrowserRouter>
        <UserContext.Provider value={{ user: user, setUser: setUser }}>
          <Routes>
            <Route exact index element={<Login />} />
            <Route path="/members/carReportPerMonth" element={<ProtectedRoute><RentedCars /></ProtectedRoute>} />
            <Route path="/members/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
            <Route path="/members/addCar" element={<ProtectedRoute><AddCar /></ProtectedRoute>} />
            <Route path="/members/addUser" element={<ProtectedRoute><AddUser /></ProtectedRoute>} />
            <Route path="/members/changePass" element={<ProtectedRoute><ChangePassword /></ProtectedRoute>} />
            <Route path="/members/commissions" element={<ProtectedRoute><Commissions /></ProtectedRoute>} />
            <Route path="/members/asiguratori" element={<ProtectedRoute><Insurers /></ProtectedRoute>} />
            <Route path="/members/locations" element={<ProtectedRoute><Locations /></ProtectedRoute>} />
          </Routes>
        </UserContext.Provider>
      </BrowserRouter>
    </div>
  );
}

export default App;
