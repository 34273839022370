import { format } from "date-fns";

const formatDate = (dateStr) => {
  if (!dateStr) {
    return "Data invalidă"; // sau returnează null sau un string gol
  }

  const date = new Date(dateStr);

  // Verifică dacă data este validă
  if (isNaN(date.getTime())) {
    return "Data invalidă"; // sau returnează un mesaj corespunzător
  }

  return format(date, "dd.MM.yyyy HH:mm");
};

const formatLocalDateTime = (dateStr) => {
  const date = new Date(dateStr);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  return `${year}-${month}-${day}T${hours}:${minutes}`;
};

const formatLocalDateTimeWithoutT = (dateStr) => {
  const date = new Date(dateStr);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}`;
};

const formatDateForDisplay = (dateStr) => {
  if (!dateStr) return "";
  return dateStr.replace("T", " ");
};

// Formatează data înapoi pentru a fi compatibilă cu `datetime-local` la salvare
const formatDateForInput = (dateStr) => {
  if (!dateStr) return "";
  return dateStr.replace(" ", "T");
};

export {
  formatLocalDateTime,
  formatLocalDateTimeWithoutT,
  formatDate,
  formatDateForDisplay,
  formatDateForInput,
};
