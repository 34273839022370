import React, { useState, useEffect } from "react";
import Axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Container,
  // Typography,
  Grid
} from "@mui/material";
import { EditUserModalButon } from "./modal/editUserModal";
import { DeleteUserButton } from "./modal/deleteUserModal";

const UsersList = ({ userIdStorage, userRole }) => {
  // console.log("userIdStorage", { userIdStorage });
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [listUsers, setListUsers] = useState([]);
  const [dataFromChild, setDataFromChild] = useState(0);

  const getDataFromChildEdit = (data) => {
    setDataFromChild(data);
  };

  const getDataFromChildDelete = (data) => {
    setDataFromChild(data);
  };

  useEffect(() => {
    Axios.get(`${BASE_URL}/getUsersListByUserId/${userIdStorage}`).then(
      (res) => {
        setListUsers(res.data);
        // console.log({ users: res.data });
      }
    );
  }, [dataFromChild]);

  return (
    <Container>
      {/* <Typography variant="h4" component="h2" gutterBottom>
        Lista utilizatori
      </Typography> */}
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="users table">
          <TableHead sx={{ backgroundColor: "#000000" }}>
            <TableRow>
              <TableCell sx={{ color: "#ffffff" }}>#</TableCell>
              <TableCell sx={{ color: "#ffffff" }}>Username</TableCell>
              <TableCell sx={{ color: "#ffffff" }}>Nume</TableCell>
              <TableCell sx={{ color: "#ffffff" }}>Prenume</TableCell>
              <TableCell sx={{ color: "#ffffff" }}>Rol</TableCell>
              <TableCell sx={{ color: "#ffffff" }}>Acțiuni</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {listUsers.map((item, i) => (
              // console.log("item", item),
              <TableRow
                key={i}
                sx={{
                  backgroundColor: i % 2 === 0 ? "action.hover" : "inherit"
                }}
              >
                <TableCell>{i + 1}</TableCell>
                <TableCell>{item.username}</TableCell>
                <TableCell>{item.nume}</TableCell>
                <TableCell>{item.prenume}</TableCell>
                <TableCell>{item.role_name}</TableCell>
                <TableCell>
                  <EditUserModalButon
                    userId={item.id}
                    username={item.username}
                    nume={item.nume}
                    prenume={item.prenume}
                    role={item.role_name}
                    idRole={item.idRole}
                    onSubmitedEdit={getDataFromChildEdit}
                  />
                  {((userRole === 1 && (item.idRole === 2 || item.idRole === 3)) ||
                    (userRole === 2 && (item.idParinte === userIdStorage || item.idRole === 3))) && (
                    <DeleteUserButton
                      userId={item.id}
                      username={item.username}
                      onSubmitedDelete={getDataFromChildDelete}
                    />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Paper sx={{ padding: 2, marginTop: 3 }}>
        <Grid container spacing={2} justifyContent="center">
          <Grid item></Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default UsersList;
