import React, { useState, useEffect } from "react";
import {
  Modal,
  Box,
  Button,
  MenuItem,
  Select,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CancelIcon from "@mui/icons-material/Cancel";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";

const RepairModal = ({
  contract,
  contractId,
  onClose,
  open,
  handleChangeDatas,
  onTipDaunaChange
}) => {
  const [tipDauna, setTipDauna] = useState("");
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false); // Starea pentru dialogul de confirmare

  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const handleTipDaunaChange = (e) => {
    const selectedValue = e.target.value;
    setTipDauna(selectedValue);
    onTipDaunaChange(selectedValue, contractId); // Apelează funcția din ContractsList
  };

  useEffect(() => {
    if (contract && contract.tipDauna) {
      setTipDauna(contract.tipDauna);
    }
  }, [contract]);

  const handleSave = async () => {
    try {
      const response = await fetch(`${BASE_URL}/updateRepairType`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ ContractId: contractId, tipDauna })
      });

      if (response.ok) {
        handleChangeDatas();
        onClose();
      } else {
        console.error("Eroare la salvarea statusului de reparație");
      }
    } catch (error) {
      console.error("Eroare de rețea:", error);
    }
  };

  const handleDeleteConfirm = () => {
    setConfirmDeleteOpen(true); // Deschide dialogul de confirmare
  };

  const handleDelete = async () => {
    try {
      console.log("contract.idContract", contractId);
      const response = await fetch(`${BASE_URL}/deleteRepairType`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ ContractId: contractId })
      });

      if (response.ok) {
        handleChangeDatas();
        onClose();
      } else {
        console.error("Eroare la ștergerea statusului de reparație");
      }
      setConfirmDeleteOpen(false); // Închide dialogul de confirmare
    } catch (error) {
      console.error("Eroare de rețea:", error);
    }
  };

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute", // Corecția poziționării
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)", // Asigură centrarea pe ecran
            width: "400px",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: "10px"
          }}
        >
          <h2>Tip Dauna</h2>
          <Select
            value={tipDauna}
            onChange={handleTipDaunaChange}
            fullWidth
            displayEmpty
          >
            <MenuItem value="" disabled>
              Selecteaza o optiune
            </MenuItem>
            <MenuItem value="service">Reparatie in service</MenuItem>
            <MenuItem value="regie proprie">
              Reparatie in regie proprie
            </MenuItem>
            <MenuItem value="dauna totala">Dauna totala</MenuItem>
          </Select>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 2
            }}
          >
            <Button
              variant="outlined"
              onClick={handleSave}
              color="primary"
              startIcon={<SaveIcon />}
            >
              Salvează
            </Button>

            <Button
              variant="outlined"
              onClick={handleDeleteConfirm}
              color="error"
              startIcon={<DeleteIcon />}
            >
              Șterge
            </Button>
            <Button
              variant="contained"
              onClick={onClose}
              style={{
                position: "absolute",
                top: "7px",
                right: "7px",
                minWidth: "0",
                padding: "0",
                color: "red",
                backgroundColor: "transparent",
                boxShadow: "none"
              }}
            >
              <CloseIcon />
            </Button>
          </Box>
        </Box>
      </Modal>

      <Dialog
        open={confirmDeleteOpen}
        onClose={() => setConfirmDeleteOpen(false)}
        sx={{ textAlign: "center", padding: "20px" }}
      >
        <DialogTitle>Confirmare ștergere</DialogTitle>
        <DialogContent>
          <p>Ești sigur că vrei să ștergi?</p>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setConfirmDeleteOpen(false)}
            color="primary"
            variant="outlined"
            startIcon={<CancelIcon />}
          >
            Anulează
          </Button>
          <Button
            onClick={handleDelete} // Funcția care efectuează ștergerea
            color="error"
            variant="outlined"
            startIcon={<DeleteIcon />}
          >
            Șterge
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RepairModal;
