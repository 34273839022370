import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Axios from "axios";

const EditCarModalButon = (props) => {
  const [show, setShow] = useState(false);
  const [errors, setErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [inputFields, setInputFields] = useState({
    carId: props.carId,
    nrAuto: props.nrAuto || "",
    categoria: props.categoria || "",
    marca: props.marca || "",
    model: props.model || "",
    culoare: props.culoare || "",
    vin: props.vin || "",
    civ: props.civ || "",
    cilindree: props.cilindree || "",
    mma: props.mma || "",
    an: props.an || "",
    tarif1: props.tarif1 || "",
    tarif2: props.tarif2 || "",
    tarif3: props.tarif3 || "",
    tarif4: props.tarif4 || "",
    tarif5: props.tarif5 || ""
  });

  useEffect(() => {
    if (submitting && Object.keys(errors).length === 0) {
      finishSubmit();
    }
  }, [errors, submitting]); // declanșează finishSubmit doar când submitting e true și nu există erori

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const validateValues = (inputValues) => {
    const requiredFields = [
      "nrAuto",
      "categoria",
      "marca",
      "model",
      "culoare",
      "vin",
      "civ",
      "cilindree",
      "mma",
      "an",
      "tarif1",
      "tarif2",
      "tarif3",
      "tarif4",
      "tarif5"
    ];
    let errors = {};

    requiredFields.forEach((field) => {
      if (!inputValues[field] || inputValues[field].length === 0) {
        errors[field] = `Completeaza ${field}`;
      }
    });

    return errors;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputFields((prevFields) => ({
      ...prevFields,
      [name]: value
    }));
  };

  const handleSubmit = (p) => {
    p.preventDefault();
    setErrors(validateValues(inputFields));
    setSubmitting(true);
  };

  const finishSubmit = async () => {
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const userData = {
      carId: props.carId,
      ...inputFields
    };

    await Axios.post(`${BASE_URL}/editCar/${props.carId}`, userData).then(
      () => {
        props.onSubmitedEdit(new Date());
      }
    );

    handleClose();
  };

  useEffect(() => {
    setInputFields({
      carId: props.carId,
      nrAuto: props.nrAuto || "",
      categoria: props.categoria || "",
      marca: props.marca || "",
      model: props.model || "",
      culoare: props.culoare || "",
      vin: props.vin || "",
      civ: props.civ || "",
      cilindree: props.cilindree || "",
      mma: props.mma || "",
      an: props.an || "",
      tarif1: props.tarif1 || "",
      tarif2: props.tarif2 || "",
      tarif3: props.tarif3 || "",
      tarif4: props.tarif4 || "",
      tarif5: props.tarif5 || ""
    });
  }, [props]);

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        <i className="bi bi-pencil-square"></i>
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Editeaza masina {props.nrAuto}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              {[
                "nrAuto",
                "categoria",
                "marca",
                "model",
                "culoare",
                "vin",
                "civ",
                "cilindree",
                "mma",
                "an",
                "tarif1",
                "tarif2",
                "tarif3",
                "tarif4",
                "tarif5"
              ].map((field) => (
                <Col md={6} key={field}>
                  <Form.Group className="mb-3" controlId={`formBasic${field}`}>
                    <Form.Label>{field}</Form.Label>
                    <Form.Control
                      type="text"
                      name={field}
                      value={inputFields[field] || ""}
                      onChange={handleChange}
                      style={{ border: errors[field] ? "1px solid red" : null }}
                    />
                    {errors[field] && <p className="error">{errors[field]}</p>}
                  </Form.Group>
                </Col>
              ))}
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="success" type="submit">
              Save Changes
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default EditCarModalButon;
