import React from "react";

import Header from "../../navigation/header";
import Title from "../../navigation/title";
import InsuranceAddForm from "./insuranceAddForm";
import InsurersList from "./insurersList";
import "../../App.css";
import "bootstrap/dist/css/bootstrap.min.css";

const Insurers = () => {

  const userStorage = sessionStorage.getItem("user");

  if (userStorage) {
    var user = JSON.parse(userStorage);
    // console.log({user1: user.id})
  }

  return (
    <div>
      <Header />
      <Title title="Lista asiguratori" />
      <div style={{marginBottom: "20px"}}>
        <InsuranceAddForm userId={user.id} />
      </div>
      <div className="table-responsive">
        <InsurersList />
      </div>
    </div>
  );
};

export default Insurers;
