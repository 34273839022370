import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Axios from "axios";
import AddIcon from "@mui/icons-material/Add";

function AddCarComponent() {
  const [open, setOpen] = useState(false);
  const [inputFields, setInputFields] = useState({
    numarAuto: "",
    categoria: "",
    marca: "",
    model: "",
    culoare: "",
    serieSasiu: "",
    serieCarteIdentitate: "",
    capacitateCilindrica: "",
    mma: "",
    anulFabricatiei: "",
    tarif1: "",
    tarif2: "",
    tarif3: "",
    tarif4: "",
    tarif5: ""
  });
  const [errors, setErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const validateValues = (inputValues) => {
    let errors = {};
    if (inputValues.numarAuto.length === 0) {
      errors.numarAuto = "Completeaza numarul auto";
    }
    if (inputValues.marca.length === 0) {
      errors.marca = "Completeaza marca";
    }
    if (inputValues.model.length === 0) {
      errors.model = "Completeaza modelul";
    }
    if (inputValues.culoare.length === 0) {
      errors.culoare = "Completeaza culoarea";
    }
    if (inputValues.serieSasiu.length === 0) {
      errors.serieSasiu = "Completeaza seria de sasiu";
    }
    if (inputValues.serieCarteIdentitate.length === 0) {
      errors.serieCarteIdentitate = "Completeaza seria cartii de identitate";
    }
    if (inputValues.capacitateCilindrica.length === 0) {
      errors.capacitateCilindrica = "Completeaza capacitatea cilindrica";
    }
    if (inputValues.mma.length === 0) {
      errors.mma = "Completeaza masa maxima autorizata";
    }
    if (inputValues.anulFabricatiei.length === 0) {
      errors.anulFabricatiei = "Completeaza anul fabricatiei";
    }
    if (inputValues.tarif1.length === 0) {
      errors.tarif1 = "Completeaza tariful 1";
    }
    if (inputValues.tarif2.length === 0) {
      errors.tarif2 = "Completeaza tariful 2";
    }
    if (inputValues.tarif3.length === 0) {
      errors.tarif3 = "Completeaza tariful 3";
    }
    if (inputValues.tarif4.length === 0) {
      errors.tarif4 = "Completeaza tariful 4";
    }
    if (inputValues.tarif5.length === 0) {
      errors.tarif5 = "Completeaza tariful 5";
    }
    if (inputValues.categoria.length === 0) {
      errors.categoria = "Completeaza categoria masinii";
    }
    return errors;
  };

  const handleChange = (e) => {
    setInputFields({ ...inputFields, [e.target.name]: e.target.value });
  };

  const handleSubmit = (p) => {
    p.preventDefault();
    setErrors(validateValues(inputFields));
    setSubmitting(true);
  };

  const finishSubmit = async () => {
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const userData = {
      numarAuto: inputFields.numarAuto,
      categoria: inputFields.categoria,
      marca: inputFields.marca,
      model: inputFields.model,
      culoare: inputFields.culoare,
      serieSasiu: inputFields.serieSasiu,
      serieCarteIdentitate: inputFields.serieCarteIdentitate,
      capacitateCilindrica: inputFields.capacitateCilindrica,
      mma: inputFields.mma,
      anulFabricatiei: inputFields.anulFabricatiei,
      tarif1: inputFields.tarif1,
      tarif2: inputFields.tarif2,
      tarif3: inputFields.tarif3,
      tarif4: inputFields.tarif4,
      tarif5: inputFields.tarif5
    };
    await Axios.post(`${BASE_URL}/addCar`, userData).then(() => {
      handleClose();
    });

    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  useEffect(() => {
    if (Object.keys(errors).length === 0 && submitting) {
      finishSubmit();
    }
  }, [errors]);

  return (
    <>
      <Button
        variant="contained"
        color="success"
        onClick={handleClickOpen}
        style={{ marginTop: "20px" }}
      >
        <AddIcon />
        Adauga masina
      </Button>

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle>Adauga masina</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              {Object.keys(inputFields).map((field, index) => (
                <Grid item xs={12} sm={6} key={index}>
                  <TextField
                    margin="dense"
                    label={field.replace(/([A-Z])/g, " $1").toUpperCase()}
                    type="text"
                    fullWidth
                    variant="outlined"
                    name={field}
                    value={inputFields[field]}
                    onChange={handleChange}
                    error={Boolean(errors[field])}
                    helperText={errors[field] || ""}
                  />
                </Grid>
              ))}
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Anuleaza
          </Button>
          <Button
            onClick={handleSubmit}
            color="success"
            type="submit"
            variant="contained"
          >
            <AddIcon />
            Adauga masina
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default AddCarComponent;
