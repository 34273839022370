import React, { useState } from "react";
import Header from "../../navigation/header";
import Title from "../../navigation/title";
import "../../App.css";
import { Button } from "@mui/material";
import UsersList from "./addUsersListComp";
import AddUserComponent from "./addUserComponent";
import PersonAddIcon from "@mui/icons-material/PersonAdd";

const AddUser = () => {

  const userStorage = sessionStorage.getItem("user");

  if (userStorage) {
    var user = JSON.parse(userStorage);
  }

  const [openModal, setOpenModal] = useState(false);

  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  return (
    <div>
      <Header />
      <Title title="Lista utilizatori" />
      <div>
        <Button
          variant="contained"
          color="success"
          style={{ marginTop: "20px", marginBottom: "20px" }}
          onClick={handleOpen}
        >
          <PersonAddIcon style={{ paddingRight: "4px" }} />
          Adauga utilizator
        </Button>
      </div>
      <div className="table-responsive">
        <UsersList userIdStorage={user?.id} userRole={user.idRole} />
      </div>
      <AddUserComponent
        open={openModal}
        handleClose={handleClose}
        userId={user?.id}
        userRole={user.idRole}
      />
    </div>
  );
};

export default AddUser;
