import React, { useState, useEffect } from "react";
import ContractForm from "./addContractForm";
import ViewEditFileModal from "./modal/viewEditFileModal";
import RepairModal from "./modal/repairModal";
import HoursModal from "./modal/hoursModal";
import DaysModal from "./modal/daysModal";
import ContractDetails from "./contracDetails";
import CarSelectModal from "./modal/carSelectModal";
import DateTimeModal from "./modal/dateTimeModal";
import InvoiceDatePicker from "./modal/invoiceDatePicker";
import AddAmountModal from "./modal/addAmountModal";
import AddViewNotesModal from "./modal/addViewNote";

import { Button, Modal, Box, TextField } from "@mui/material";
import EditNoteIcon from "@mui/icons-material/EditNote";
import MinorCrashIcon from "@mui/icons-material/MinorCrash";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import TodayIcon from "@mui/icons-material/Today";
// import ScheduleSendIcon from "@mui/icons-material/ScheduleSend";
import TimerIcon from "@mui/icons-material/Timer";
import TimerOffIcon from "@mui/icons-material/TimerOff";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import AddBoxIcon from "@mui/icons-material/AddBox";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ReceiptIcon from "@mui/icons-material/Receipt";
import MoneyOffIcon from "@mui/icons-material/MoneyOff";
import Tooltip from "@mui/material/Tooltip";
import AddIcon from "@mui/icons-material/Add";
import PaidIcon from "@mui/icons-material/Paid";
import * as XLSX from "xlsx";

import DateFilter from "./dateFilter";
import { formatDate } from "../../utils/dateFormat";
import { formatLocalDateTimeWithoutT } from "../../utils/dateFormat";
import noImage from "../../assets/img/no-image.jpg";
import "./index.css";

const ContractsList = (props) => {
  // console.log("propsContractsList", props);
  // const [showForm, setShowForm] = useState(false);
  const [expandedRow, setExpandedRow] = useState(null); // Stocăm indexul rândului extins
  const [showUploadModal, setShowUploadModal] = useState(false); // Starea pentru modalul de upload
  const [currentContractId, setCurrentContractId] = useState(
    () => localStorage.getItem("currentContractId") || null
  );
  const [currentFile, setCurrentFile] = useState(null);
  const [activeModal, setActiveModal] = useState(null);
  const [contract, setContract] = useState(null);
  const [editValues, setEditValues] = useState({});
  const [filteredContracts, setFilteredContracts] = useState([]);
  // const [contractNotes, setContractNotes] = useState("");

  // const [tipDauna, setTipDauna] = useState("");
  const [regieProprieContracts, setRegieProprieContracts] = useState(() => {
    const stored =
      JSON.parse(localStorage.getItem("regieProprieContracts")) || {};
    return stored;
  });
  const [showDateModal, setShowDateModal] = useState(false); // Controlează vizibilitatea modalului de dată
  const [selectedDate, setSelectedDate] = useState(null); // Data selectată pentru factură

  // console.log("filteredContracts", filteredContracts);
  // console.log("props CONTRACTLIST", props);
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const FILE_UPLOAD_PATH = process.env.REACT_APP_FILE_UPLOAD_PATH;

  const user = JSON.parse(sessionStorage.getItem("user"));

  const userId = user?.id;
  const idRole = user?.idRole;

  const handleTipDaunaChange = (value, contractId) => {
    if (!contractId) return;
    const isRegie = value === "regie proprie";

    setRegieProprieContracts((prevState) => {
      const updated = { ...prevState, [contractId]: isRegie };
      localStorage.setItem("regieProprieContracts", JSON.stringify(updated));
      return updated;
    });
  };

  const handleOpen = (modalType, contractId = null, file = null) => {
    // console.log("Opening modal:", modalType);
    setActiveModal(modalType);
    setCurrentContractId(contractId);
    setCurrentFile(file);
    setShowUploadModal(true);

    if (contractId) {
      // console.log("contractIdHandleOpen", contractId);
      // console.log("filteredContracts", filteredContracts);
      localStorage.setItem("currentContractId", contractId);

      const selectedContract = filteredContracts.find(
        (contract) => contract.idContract === contractId
      );
      // console.log("selectedContract", selectedContract);
      setContract(selectedContract);
    }
  }; // Deschide modalul

  const handleClose = () => {
    // console.log("Closing modal");
    setActiveModal(null);
    setCurrentContractId(null);
    setCurrentFile(null);
    setShowUploadModal(false);
  }; // Închide modalul

  const handleEditChange = (field, value) => {
    setEditValues((prevValues) => ({
      ...prevValues,
      [field]: value
    }));
  };

  const toggleRow = (index) => {
    setExpandedRow(expandedRow === index ? null : index); // Schimbăm starea rândului
    if (expandedRow !== index) {
      setEditValues(props.contracts[index] || {}); // Preluăm datele contractului curent pentru editare
    }
  };

  const handleSaveDetails = async (detailsData) => {
    const combinedData = {
      ...props.contracts.find(
        (contract) => contract.idContract === currentContractId
      ),
      ...editValues,
      ...detailsData
    };
    // console.log("combinedData", combinedData);
    await handleSaveData(combinedData);
    setExpandedRow(null); // Închide rândul expandat după salvare
  };

  const handleSaveData = async (data) => {
    try {
      const formData = new FormData();
      formData.append("idLocatie", data.idLocatie);
      formData.append("idManager", data.idManager);
      formData.append("idAgent", data.idAgent);
      formData.append("numePagubit", data.numePagubit);
      formData.append("cnp", data.cnp || "");
      formData.append("telefon", data.telefon || "");
      formData.append("adresa", data.adresa || "");
      formData.append(
        "dataEveniment",
        formatLocalDateTimeWithoutT(data.dataEveniment)
      );
      formData.append("numarDosar", data.numarDosar || "");
      formData.append("asigurator", data.asigurator || "");
      formData.append("inspector", data.inspector || "");
      formData.append("idContract", data.idContract);
      formData.append(
        "dataConstatare",
        formatLocalDateTimeWithoutT(data.dataConstatare)
      );
      formData.append("conducatorAuto", data.conducatorAuto || "");
      formData.append("conducatorAutoCi", data.conducatorAutoCi || "");
      formData.append(
        "conducatorAutoTelefon",
        data.conducatorAutoTelefon || ""
      );

      // Adaugă imaginile, dacă există
      if (data.pozeActe && typeof data.pozeActe === "object") {
        Object.entries(data.pozeActe).forEach(([key, value]) => {
          if (value && value.file instanceof File) {
            formData.append(`pozeActe[${key}]`, value.file);
          }
        });
      }

      if (data.pozeMasina && typeof data.pozeMasina === "object") {
        Object.entries(data.pozeMasina).forEach(([key, value]) => {
          if (value && value.file instanceof File) {
            formData.append(`pozeMasina[${key}]`, value.file);
          }
        });
      }

      if (Array.isArray(data.pozeEveniment)) {
        data.pozeEveniment.forEach((poza, index) => {
          if (poza && poza.file instanceof File) {
            formData.append(`pozeEveniment[${index}]`, poza.file);
          }
        });
      }

      const response = await fetch(
        `${BASE_URL}/addEditContract?contractId=${data.idContract}`,
        {
          method: "POST",
          body: formData
        }
      );

      if (response.ok) {
        // console.log("Contract updated successfully");
        props.handleChangeData();
      } else {
        console.error("Failed to update contract");
      }
    } catch (error) {
      console.error("Error updating contract:", error);
    }
  };

  const getContractData = (idContract) => {
    // Găsește contractul curent
    const contract = props.contracts.find((c) => c.idContract === idContract);

    if (!contract) {
      console.error("Contractul nu a fost găsit.");
      return null;
    }

    // Găsește locația asociată
    const locatie = props.locationsList.find(
      (loc) => loc.idLocatie === contract.idLocatie
    );

    // Găsește agentul asociat
    const agent = props.agentsList.find(
      (agent) => agent.id === contract.idAgent
    );
    // console.log("agent", agent);

    // Găsește managerul asociat
    const manager = props.managersList.find(
      (manager) => manager.id === contract.idManager
    );

    // Găsește mașina asociată
    const masina = props.carsList.find(
      (car) => car.carId === contract.idMasina
    );

    let tarifPeZi;

    if (contract.nrZile >= 2 && contract.nrZile <= 4) {
      tarifPeZi = masina.tarif5;
    } else if (contract.nrZile >= 5 && contract.nrZile <= 8) {
      tarifPeZi = masina.tarif4;
    } else if (contract.nrZile >= 9 && contract.nrZile <= 14) {
      tarifPeZi = masina.tarif3;
    } else if (contract.nrZile >= 15 && contract.nrZile <= 29) {
      tarifPeZi = masina.tarif2;
    } else if (contract.nrZile >= 30) {
      tarifPeZi = masina.tarif1;
    }

    const contractData = {
      numePagubit: contract.numePagubit,
      nrZile: contract.nrZile,
      tarifPeZi: tarifPeZi,
      cnp: contract.cnp,
      telefon: contract.telefon,
      adresa: contract.adresa,
      locatie: locatie ? locatie.localitatea : "",
      manager: manager ? `${manager.nume} ${manager.prenume}` : "",
      agent: agent ? `${agent.nume} ${agent.prenume}` : "",
      masina: masina
        ? {
            mma: masina.mma,
            marca: masina.marca,
            model: masina.model,
            numarAuto: masina.numarAuto,
            anulFabricatiei: masina.anulFabricatiei,
            culoare: masina.culoare,
            vin: masina.vin,
            civ: masina.civ,
            cilindree: masina.cilindree,
            categoria: masina.categoria
          }
        : {},
      contractId: idContract,
      conducatorAuto: contract.conducatorAuto,
      conducatorAutoCi: contract.conducatorAutoCi,
      conducatorAutoTelefon: contract.conducatorAutoTelefon
      // Adaugă alte câmpuri dacă este nevoie
    };

    return contractData;
  };

  const handleCreateContract = async (idContract) => {
    const contractData = getContractData(idContract);
    // console.log("contractData", contractData);
    if (!contractData) {
      return;
    }

    try {
      const response = await fetch(`${BASE_URL}/generateContract`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(contractData)
      });

      if (response.ok) {
        props.handleChangeData();
        const blob = await response.blob();
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `contract_${idContract}.docx`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      } else {
        console.error("Eroare la generarea contractului docx");
      }
    } catch (error) {
      console.error("Eroare:", error);
    }
  };

  const handleExportExcel = () => {
    if (filteredContracts.length === 0) {
      console.warn("Nu există contracte de exportat!");
      return;
    }
    // console.log("filteredContracts", filteredContracts);
    // Transformă datele în format JSON pentru a fi exportate în Excel
    const dataToExport = filteredContracts.map((contract) => ({
      "Nr. contract": contract.idContract,
      Locatie: contract.locatie,
      Manager: `${contract.manager}`,
      Agent: `${contract.agent}`,
      "Nume Pagubit": contract.numePagubit,
      CNP: contract.cnp,
      Telefon: contract.telefon,
      Adresa: contract.adresa,
      "Data Eveniment": formatLocalDateTimeWithoutT(contract.dataEveniment),
      "Nota Constatare": contract.notaConstatare ? "Există" : "Nu există",
      "Tip Dauna": contract.tipDauna,
      "Nr Ore": contract.nrOre,
      "Nr Zile": contract.nrZile,
      "Data Inceput": formatLocalDateTimeWithoutT(contract.dataInceput),
      "Data Sfarsit": formatLocalDateTimeWithoutT(contract.dataSfarsit),
      Masina: contract.nrAuto,
      "Total facturat": contract.totalFacturat,
      "Total incasat": contract.totalIncasat
    }));

    // Creează un workbook nou
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(dataToExport);

    // Setăm lățimea coloanelor
    worksheet["!cols"] = [
      { wpx: 80 }, // Lățimea pentru "Nr. contract" în pixeli
      { wpx: 120 }, // Lățimea pentru "Locatie"
      { wpx: 150 }, // Lățimea pentru "Manager"
      { wpx: 150 }, // Lățimea pentru "Agent"
      { wpx: 150 }, // Lățimea pentru "Nume Pagubit"
      { wpx: 100 }, // Lățimea pentru "CNP"
      { wpx: 70 }, // Lățimea pentru "Telefon"
      { wpx: 180 }, // Lățimea pentru "Adresa"
      { wpx: 120 }, // Lățimea pentru "Data Eveniment"
      { wpx: 100 }, // Lățimea pentru "Nota Constatare"
      { wpx: 80 }, // Lățimea pentru "Tip Dauna"
      { wpx: 70 }, // Lățimea pentru "Nr Ore"
      { wpx: 70 }, // Lățimea pentru "Nr Zile"
      { wpx: 120 }, // Lățimea pentru "Data Inceput"
      { wpx: 120 }, // Lățimea pentru "Data Sfarsit"
      { wpx: 80 }, // Lățimea pentru "Masina"
      { wpx: 80 }, // Lățimea pentru "Total facturat"
      { wpx: 80 } // Lățimea pentru "Total incasat"
    ];

    // Adaugă worksheet-ul în workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Contracte");

    // Salvează workbook-ul ca fișier Excel
    XLSX.writeFile(workbook, "contracte_exportate.xlsx");
  };

  const handleOpenDateModal = (contractId) => {
    handleOpen("datePicker", contractId, ""); // Deschide modalul folosind `handleOpen`
    setShowDateModal(true);
  };

  const handleGenerateInvoiceWithDate = async () => {
    const contractData = getContractData(currentContractId);
    if (!contractData) return;

    if (selectedDate) {
      // Setează data în format local fără UTC
      const year = selectedDate.getFullYear();
      const month = String(selectedDate.getMonth() + 1).padStart(2, "0"); // luni de la 0 la 11
      const day = String(selectedDate.getDate()).padStart(2, "0");
      contractData.dataFactura = `${year}-${month}-${day}`;
    }
    // console.log("contractData", contractData);
    try {
      const response = await fetch(`${BASE_URL}/generateInvoice`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(contractData)
      });

      if (response.ok) {
        props.handleChangeData();
        const blob = await response.blob();
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.setAttribute("download", `factura_${currentContractId}.docx`);
        link.href = url;
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      } else {
        console.error("Eroare la generarea facturii word");
      }
    } catch (error) {
      console.error("Eroare:", error);
    } finally {
      setShowDateModal(false); // Închide modalul după generare
    }
  };

  const handleCashedAmount = async (contractId, amount) => {
    try {
      const response = await fetch(
        `${BASE_URL}/setCashedAmount/${contractId}/${amount}`,
        {
          method: "POST", // Specifice metoda POST
          headers: {
            "Content-Type": "application/json" // Dacă e necesar să trimiți date JSON
          },
          body: JSON.stringify({ contractId, amount }) // Dacă vrei să trimiti un corp de cerere
        }
      );

      if (response.ok) {
        // console.log("Suma a fost adăugată cu succes!");
        props.handleChangeData();
      } else {
        const error = await response.json();
        console.error("Eroare la adăugarea sumei:", error.message);
      }
    } catch (err) {
      console.error("Eroare în timpul comunicării cu serverul:", err);
    }
  };

  const handleAddEditNotes = async (contractId, notes) => {
    try {
      const response = await fetch(`${BASE_URL}/addEditNotes/${contractId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ contractId, notes })
      });

      if (response.ok) {
        // console.log("Notele au fost adăugate cu succes!");
        props.handleChangeData();
      } else {
        const error = await response.json();
        console.error("Eroare la adăugarea notelor:", error.message);
      }
      // alert("Eroare la adăugarea notelor!");
    } catch (err) {
      console.error("Eroare în timpul comunicării cu serverul:", err);
    }
  };

  useEffect(() => {
    // console.log("contracteListDinDashboard", props.contracts);
    // console.log("userId", userId);
    // console.log("idRole", idRole);
    setFilteredContracts(props.contracts);
  }, [props.contracts, userId, idRole]);

  return (
    <div>
      <Button
        open={showUploadModal}
        variant="contained"
        onClick={() => handleOpen("addContract", null, null)}
      >
        <AddIcon />
        {showUploadModal ? " Anulează" : " Adaugă Contract"}
      </Button>

      {activeModal === "addContract" && (
        <Modal open={showUploadModal} onClose={handleClose}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 1000,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              borderRadius: "10px"
            }}
          >
            <ContractForm
              userId={userId}
              userRole={idRole}
              locationsList={props.locationsList}
              managersList={props.managersList}
              agentsList={props.agentsList}
              agentsListByParentId={props.agentsListByParentId}
              onClose={handleClose}
            />
          </Box>
        </Modal>
      )}

      <DateFilter getContractsList={props.getContractsList} />
      <div className="table-responsive" style={{ marginTop: "20px" }}>
        <Button onClick={handleExportExcel} variant="contained" color="success">
          <FileDownloadIcon />
          Excel
        </Button>
      </div>

      <div className="table-responsive" style={{ marginTop: "20px" }}>
        <table className="table-container">
          <thead className="table-header">
            <tr>
              <th style={{ width: "20px", padding: "2px" }}>Nr. contract</th>
              <th style={{ width: "130px" }}>Locatie</th>
              <th style={{ width: "150px" }}>Manager</th>
              <th style={{ width: "180px" }}>Agent</th>
              <th style={{ width: "150px" }}>Nume Pagubit</th>
              <th style={{ width: "150px" }}>CNP</th>
              <th style={{ width: "150px" }}>Telefon</th>
              <th style={{ width: "150px" }}>Adresa</th>
              <th style={{ width: "150px" }}>Data eveniment</th>
              <th style={{ width: "20px", padding: "2px" }}>Nota Constatare</th>
              <th style={{ width: "80px" }}>Tip Dauna</th>
              <th style={{ width: "20px", padding: "2px" }}>Nr Ore</th>
              <th style={{ width: "20px", padding: "2px" }}>Nr Zile</th>
              <th style={{ width: "80px" }}>Data Inceput</th>
              <th style={{ width: "80px" }}>Data Sfarsit</th>
              <th style={{ width: "100px" }}>Masina</th>
              <th style={{ width: "20px" }}>Total facturat</th>
              <th style={{ width: "20px", padding: "2px" }}>Total incasat</th>
              <th style={{ width: "20px", padding: "2px" }}>Incasat /zi</th>
              <th style={{ width: "20px", padding: "2px" }}>
                Genereaza factura
              </th>
              <th style={{ width: "20px", padding: "2px" }}>
                Genereaza contract
              </th>
              <th style={{ width: "20px", padding: "2px" }}>Note</th>
              <th style={{ width: "20px", padding: "2px" }}>Mai multe</th>
            </tr>
          </thead>
          <tbody className="table-body">
            {props.contracts?.map((row, index) => (
              <React.Fragment key={index}>
                <tr>
                  <td>{row.idContract}</td>
                  <td>
                    {expandedRow === index ? (
                      <TextField
                        value={
                          editValues.idLocatie ??
                          props.contracts[index]?.idLocatie ??
                          ""
                        } // Dacă `editValues.idLocatie` este `undefined`, folosește valoarea din contract
                        onChange={(e) =>
                          handleEditChange("idLocatie", e.target.value)
                        }
                        select
                        SelectProps={{
                          native: true
                        }}
                        fullWidth
                      >
                        {props?.locationsList.map((location) => (
                          <option
                            key={location.idLocatie}
                            value={location.idLocatie}
                          >
                            {location.localitatea}
                          </option>
                        ))}
                      </TextField>
                    ) : (
                      row.locatie
                    )}
                  </td>
                  <td>
                    {expandedRow === index ? (
                      <TextField
                        value={
                          editValues.idManager ??
                          props.contracts[index]?.idManager ??
                          ""
                        }
                        onChange={(e) =>
                          handleEditChange("idManager", e.target.value)
                        }
                        select
                        SelectProps={{
                          native: true
                        }}
                        fullWidth
                      >
                        {props?.managersList.map((manager) => (
                          <option key={manager.id} value={manager.id}>
                            {manager.nume} {manager.prenume}
                          </option>
                        ))}
                      </TextField>
                    ) : (
                      row.manager
                    )}
                  </td>
                  {/* Nume agent */}
                  <td>
                    {expandedRow === index ? (
                      <TextField
                        value={
                          editValues.idAgent ??
                          props.contracts[index]?.idAgent ??
                          ""
                        }
                        onChange={(e) =>
                          handleEditChange("idAgent", e.target.value)
                        }
                        select
                        SelectProps={{
                          native: true
                        }}
                        fullWidth
                      >
                        {props?.agentsList.map((agent) => (
                          <option key={agent.id} value={agent.id}>
                            {agent.nume} {agent.prenume}
                          </option>
                        ))}
                      </TextField>
                    ) : (
                      row.agent
                    )}
                  </td>
                  {/* nume pagubit */}
                  <td>
                    {expandedRow === index ? (
                      <TextField
                        value={
                          editValues.numePagubit ??
                          props.contracts[index]?.numePagubit ??
                          ""
                        }
                        onChange={(e) =>
                          handleEditChange("numePagubit", e.target.value)
                        }
                        fullWidth
                      />
                    ) : (
                      row.numePagubit
                    )}
                  </td>
                  {/* CNP */}
                  <td>
                    {expandedRow === index ? (
                      <TextField
                        value={
                          editValues.cnp ?? props.contracts[index]?.cnp ?? ""
                        }
                        onChange={(e) =>
                          handleEditChange("cnp", e.target.value)
                        }
                        fullWidth
                      />
                    ) : (
                      row.cnp
                    )}
                  </td>
                  {/* telefonul */}
                  <td>
                    {expandedRow === index ? (
                      <TextField
                        value={
                          editValues.telefon ??
                          props.contracts[index]?.telefon ??
                          ""
                        }
                        onChange={(e) =>
                          handleEditChange("telefon", e.target.value)
                        }
                        fullWidth
                      />
                    ) : (
                      row.telefon
                    )}
                  </td>
                  {/* adresa */}
                  <td>
                    {expandedRow === index ? (
                      <TextField
                        value={
                          editValues.adresa ??
                          props.contracts[index]?.adresa ??
                          ""
                        }
                        onChange={(e) =>
                          handleEditChange("adresa", e.target.value)
                        }
                        fullWidth
                      />
                    ) : (
                      row.adresa
                    )}
                  </td>
                  {/* Adaugam data evenimentului */}
                  <td>
                    {expandedRow === index ? (
                      <TextField
                        type="datetime-local"
                        value={
                          editValues.dataEveniment
                            ? editValues.dataEveniment.split(".")[0]
                            : row.dataEveniment
                              ? row.dataEveniment.split(".")[0]
                              : ""
                        }
                        onChange={(e) =>
                          handleEditChange("dataEveniment", e.target.value)
                        }
                        fullWidth
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    ) : row.dataEveniment ? (
                      formatDate(row.dataEveniment, "DD-MM-YYYY HH:mm")
                    ) : (
                      <EventAvailableIcon fontSize="medium" />
                    )}
                  </td>
                  {/* Adaugam nota de constatare */}
                  <td>
                    {/* {`${BASE_URL}${FILE_UPLOAD_PATH}/${row.notaConstatare}`} */}
                    {row.notaConstatare ? (
                      <img
                        src={`${BASE_URL}/${FILE_UPLOAD_PATH}/${row.notaConstatare}`}
                        style={{ width: "50px", height: "50px" }}
                        alt="Nota de constatare"
                        onClick={() =>
                          handleOpen(
                            "notaConstatareUpload",
                            row.idContract,
                            row.notaConstatare
                          )
                        } // Deschidem modalul de vizualizare/editare
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = noImage;
                        }}
                      />
                    ) : (
                      <EditNoteIcon
                        fontSize="medium"
                        onClick={() =>
                          handleOpen(
                            "notaConstatareUpload",
                            row.idContract,
                            null
                          )
                        } // Deschidem modalul pentru upload
                        style={{ cursor: "pointer" }}
                      />
                    )}
                  </td>
                  {/* // Alegem tip reparatie */}
                  <td>
                    {row.tipDauna ? (
                      <span
                        onClick={() =>
                          handleOpen("tipDauna", row.idContract, row.tipDauna)
                        }
                        style={{ cursor: "pointer" }}
                      >
                        {row.tipDauna}
                      </span>
                    ) : (
                      <MinorCrashIcon
                        onClick={() => handleOpen("tipDauna", row.idContract)}
                      />
                    )}
                  </td>
                  {/* Adaugam nr ore */}
                  <td>
                    {row.nrOre ? (
                      <span
                        onClick={() =>
                          handleOpen("nrOre", row.idContract, row.nrOre)
                        }
                        style={{ cursor: "pointer" }}
                      >
                        {row.nrOre}
                      </span>
                    ) : (
                      <QueryBuilderIcon
                        onClick={() => {
                          handleOpen("nrOre", row.idContract);
                        }}
                        style={{
                          cursor: regieProprieContracts[row.idContract]
                            ? "not-allowed"
                            : "pointer",
                          opacity: regieProprieContracts[row.idContract]
                            ? 0.5
                            : 1
                        }} // Modifică stilul în funcție de starea isRegieProprie
                      />
                    )}
                  </td>
                  <td>
                    {row.nrZile ? (
                      <span>{row.nrZile}</span>
                    ) : (
                      <TodayIcon
                        style={{
                          cursor: regieProprieContracts[row.idContract]
                            ? "not-allowed"
                            : "",
                          opacity: regieProprieContracts[row.idContract]
                            ? 0.5
                            : 1
                        }}
                      />
                    )}
                  </td>
                  <td>
                    {row.dataInceput && row.dataInceput !== 0 ? (
                      <span
                        onClick={() =>
                          handleOpen(
                            "dataInceput",
                            row.idContract,
                            row.dataInceput
                          )
                        }
                        style={{ cursor: "pointer" }}
                      >
                        {formatDate(row.dataInceput)}
                      </span>
                    ) : (
                      <TimerIcon
                        fontSize="medium"
                        onClick={() => {
                          if (!regieProprieContracts[row.idContract]) {
                            // Verifică dacă nu este regie proprie
                            handleOpen("dataInceput", row.idContract);
                          }
                        }}
                        style={{
                          cursor: regieProprieContracts[row.idContract]
                            ? "not-allowed"
                            : "pointer",
                          opacity: regieProprieContracts[row.idContract]
                            ? 0.5
                            : 1
                        }} // Modifică stilul în funcție de starea isRegieProprie
                      />
                    )}
                  </td>
                  <td>
                    {row.dataSfarsit && row.dataSfarsit !== 0 ? (
                      <span>{formatDate(row.dataSfarsit)}</span>
                    ) : (
                      <TimerOffIcon
                        fontSize="medium"
                        style={{
                          cursor: regieProprieContracts[row.idContract]
                            ? "not-allowed"
                            : "",
                          opacity: regieProprieContracts[row.idContract]
                            ? 0.5
                            : 1
                        }}
                      />
                    )}
                  </td>
                  <td>
                    {row.idMasina && row.idMasina !== 0 ? (
                      <span
                        onClick={() =>
                          handleOpen("idMasina", row.idContract, row.idMasina)
                        }
                        style={{ cursor: "pointer" }}
                      >
                        {(() => {
                          const car = props.carsList.find(
                            (car) => car.carId === row.idMasina
                          );
                          return car ? car.numarAuto : "N/A";
                        })()}
                      </span>
                    ) : (
                      <DirectionsCarIcon
                        fontSize="medium"
                        onClick={() => handleOpen("idMasina", row.idContract)}
                        style={{
                          cursor: regieProprieContracts[row.idContract]
                            ? "not-allowed"
                            : "pointer",
                          opacity: regieProprieContracts[row.idContract]
                            ? 0.5
                            : 1
                        }}
                      />
                    )}
                  </td>
                  <td>
                    {row.nrZile ? (
                      <span>
                        {/* {(() => {
                          const car = props.carsList.find(
                            (car) => car.carId === row.idMasina
                          );
                          if (car) {
                            let tarifPeZi;

                            if (row.nrZile >= 2 && row.nrZile <= 4) {
                              tarifPeZi = car.tarif1;
                            } else if (row.nrZile >= 5 && row.nrZile <= 8) {
                              tarifPeZi = car.tarif2;
                            } else if (row.nrZile >= 9 && row.nrZile <= 14) {
                              tarifPeZi = car.tarif3;
                            } else if (row.nrZile >= 15 && row.nrZile <= 29) {
                              tarifPeZi = car.tarif4;
                            } else if (row.nrZile >= 30) {
                              tarifPeZi = car.tarif5;
                            }
                            const totalFacturat = tarifPeZi * row.nrZile;
                            return `${totalFacturat} RON`;
                          }
                          return (
                            <MoneyOffIcon
                              style={{
                                cursor: "not-allowed",
                                opacity: 0.5
                              }}
                            />
                          );
                        })()} */}
                        {row.totalFacturat ? `${row.totalFacturat} RON` : ""}
                      </span>
                    ) : (
                      <Tooltip title="Completeaza mai intai Tip Dauna, Nr Ore, Data inceput si selecteaza masina pentru a genera totalul!">
                        <MoneyOffIcon
                          style={{
                            cursor: "not-allowed",
                            opacity: 0.5
                          }}
                        />
                      </Tooltip>
                    )}
                  </td>
                  {/* adauga incasare */}
                  <td>
                    {row.nrOre && row.dataInceput && row.idMasina ? (
                      <span
                        onClick={() =>
                          handleOpen("addCashedAmount", row.idContract)
                        }
                        style={{
                          cursor: "pointer"
                        }}
                      >
                        {row.totalIncasat ? (
                          `${row.totalIncasat} RON`
                        ) : (
                          <PaidIcon />
                        )}
                      </span>
                    ) : row.totalIncasat ? (
                      <span>{row.totalIncasat}</span>
                    ) : (
                      <Tooltip title="Nu ai incasari! Apasa aici pentru a adauga incasare! Daca butonul este dezactivat selecteaza Tip Dauna, apoi completeaza numarul de ore, data de inceput, selecteaza masina ">
                        <PaidIcon
                          style={{
                            cursor: "not-allowed",
                            opacity: 0.5
                          }}
                        />
                      </Tooltip>
                    )}
                  </td>
                  {/* incasat pe zi */}
                  <td>
                    {row.totalIncasat ? (
                      <span>
                        {(row.totalIncasat / row.nrZile).toFixed(2) + " RON"}
                      </span>
                    ) : (
                      <Tooltip title="Completeaza mai intai 'Total incasat' pentru a vedea costul pe zi!">
                        <PaidIcon
                          style={{
                            cursor: "not-allowed",
                            opacity: 0.5
                          }}
                        />
                      </Tooltip>
                    )}
                  </td>

                  {/* // Generează factură */}
                  <td>
                    {row.nrOre &&
                    row.dataInceput &&
                    row.idMasina &&
                    row.tipDauna !== "" ? (
                      <ReceiptIcon
                        fontSize="medium"
                        onClick={() => handleOpenDateModal(row.idContract)} // Deschide modalul pentru dată
                        style={{
                          cursor: "pointer",
                          color: row.isInvoice === 1 ? "red" : "green"
                        }}
                      />
                    ) : (
                      <Tooltip title="Completează câmpurile: numarul de ore, data de inceput, selecteaza masina și completeaza asiguratorul pentru a emite factura!">
                        <ReceiptIcon
                          fontSize="medium"
                          style={{ cursor: "not-allowed", opacity: 0.5 }}
                        />
                      </Tooltip>
                    )}
                  </td>
                  {/* // Generează contract */}
                  <td>
                    {row.nrOre && row.dataInceput && row.idMasina ? ( // Generează contract
                      <NoteAddIcon
                        fontSize="medium"
                        onClick={() => handleCreateContract(row.idContract)}
                        style={{
                          cursor: regieProprieContracts[row.idContract]
                            ? "not-allowed"
                            : "pointer",
                          opacity: regieProprieContracts[row.idContract]
                            ? 0.5
                            : 1,
                          color: row.isContract === 1 ? "red" : "green"
                        }}
                      />
                    ) : (
                      <Tooltip title="Completează câmpurile: numarul de ore, data de inceput si masina pentru a emite contractul SAU schimba TIP DAUNA in 'Reparatie in service' sau 'Dauna totala'!">
                        <NoteAddIcon
                          fontSize="medium"
                          style={{ cursor: "not-allowed", opacity: 0.5 }}
                        />
                      </Tooltip>
                    )}
                  </td>
                  <td>
                    <EditNoteIcon
                      onClick={() =>
                        handleOpen("note", row.idContract, row.note)
                      }
                      style={{ cursor: "pointer" }}
                    />
                  </td>
                  <td>
                    {expandedRow === index ? (
                      <IndeterminateCheckBoxIcon
                        onClick={() => toggleRow(index)}
                        style={{ cursor: "pointer" }}
                      />
                    ) : (
                      <AddBoxIcon
                        onClick={() => toggleRow(index)}
                        style={{ cursor: "pointer" }}
                      />
                    )}
                  </td>
                </tr>
                {expandedRow === index && (
                  <tr>
                    <td colSpan="23" style={{ backgroundColor: "#f9f9f9" }}>
                      <ContractDetails
                        contracts={props.contracts}
                        idContract={row.idContract}
                        imagesList={props.imagesList}
                        insurersList={props.insurersList}
                        onSave={handleSaveDetails}
                      />
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>

      {activeModal === "notaConstatareUpload" && (
        <ViewEditFileModal
          open={showUploadModal}
          onClose={handleClose}
          contractId={currentContractId}
          currentFile={currentFile}
          contracts={props.contracts}
          BASE_URL={BASE_URL}
          FILE_UPLOAD_PATH={FILE_UPLOAD_PATH}
          handleChangeDatas={props.handleChangeData}
        />
      )}
      {activeModal === "tipDauna" && (
        <RepairModal
          contract={props.contracts}
          contractId={currentContractId}
          onClose={handleClose}
          open={showUploadModal}
          handleChangeDatas={props.handleChangeData}
          onTipDaunaChange={handleTipDaunaChange}
        />
      )}
      {activeModal === "nrOre" &&
        // (console.log("contractInList", contract),
        (
          <HoursModal
            activeModal={activeModal}
            contract={contract}
            open={showUploadModal}
            onClose={handleClose}
            handleChangeDatas={props.handleChangeData}
          />
        )}
      {activeModal === "nrZile" && (
        <DaysModal
          contract={contract}
          open={showUploadModal}
          onClose={handleClose}
          handleChangeDatas={props.handleChangeData}
        />
      )}
      {activeModal === "dataInceput" && (
        <DateTimeModal
          contract={contract}
          open={showUploadModal}
          onClose={handleClose}
          handleChangeDatas={props.handleChangeData}
        />
      )}
      {activeModal === "idMasina" && (
        <CarSelectModal
          contract={contract}
          contracts={props.contracts}
          contractId={currentContractId}
          carsList={props.carsList}
          open={showUploadModal}
          onClose={handleClose}
          handleChangeDatas={props.handleChangeData}
        />
      )}
      {activeModal === "datePicker" && (
        <InvoiceDatePicker
          contract={contract}
          open={showDateModal}
          onClose={handleClose}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          onGenerateInvoice={handleGenerateInvoiceWithDate}
        />
      )}
      {activeModal === "addCashedAmount" && (
        <AddAmountModal
          contractId={currentContractId}
          open={showUploadModal}
          onClose={handleClose}
          cashedAmount={handleCashedAmount}
          handleChangeDatas={props.handleChangeData}
        />
      )}
      {activeModal === "note" && (
        <AddViewNotesModal
          contractId={currentContractId}
          open={showUploadModal}
          contractNotes={contract.note}
          onClose={handleClose}
          addEditNotes={handleAddEditNotes}
          handleChangeDatas={props.handleChangeData}
        />
      )}
    </div>
  );
};

export default ContractsList;
