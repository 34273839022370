import React, { useState, useEffect } from "react";
import { Modal, Box, Typography, TextField, Button } from "@mui/material";

// Stiluri pentru modal
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2
};

const AddViewNotesModal = ({
  open,
  onClose,
  addEditNotes,
  contractId,
  contractNotes
}) => {
  const [notes, setNotes] = useState("");

  useEffect(() => {
    if (open) {
      setNotes(contractNotes || ""); // Inițializăm cu valoarea existentă sau gol
    }
  }, [open, contractNotes]);

  const handleInputChange = (e) => {
    setNotes(e.target.value);
  };

  const handleSave = () => {
    addEditNotes(contractId, notes); // Trimitem valoarea introdusă către componenta părinte
    onClose(); // Închidem modalul
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={style}>
        <Typography variant="h6" component="h2" mb={2}>
          Adaugă notite
        </Typography>
        <TextField
          fullWidth
          label={`Notitele pentru contractul nr. ${contractId}`}
          variant="outlined"
          value={notes}
          onChange={handleInputChange}
          multiline
          rows={10}
          maxRows={6}
          type="text"
        />
        <Box mt={3} display="flex" justifyContent="space-between">
          <Button variant="contained" color="primary" onClick={handleSave}>
            Salvează
          </Button>
          <Button variant="outlined" color="secondary" onClick={onClose}>
            Anulează
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddViewNotesModal;
