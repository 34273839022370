import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Header from "../../navigation/header";
// import Title from "../../navigation/title";
import ChangePassComponent from "./changePassComponent";
import "../../App.css";

import { checkIsLoggedIn } from "./Login";

const ChangePassword = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!checkIsLoggedIn()) {
      navigate("../../auth/login/Login");
    }
  }, [navigate]);

  return (
    <div>
      <Header />
      <div className="title-and-form-container">
        {/* <Title title="Schimba parola" /> */}
        <ChangePassComponent />
      </div>
    </div>
  );
};

export default ChangePassword;
